import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SortingState } from '@tanstack/react-table';
import {
  RESTAURANT_USER_ROLE,
  useAuthUserRestaurant,
  useGetChildRestaurantsQuery,
} from '@durma-soft/gros-sdk';

import { Link } from '@/components/shared/link';
import { DataTable } from '@/components/shared/data-table';
import { buttonVariants } from '@/components/shared/shadcn-ui/button';
import { EditRestaurantPartnerModal } from '@/components/restaurant/partners/edit-restaurant-partner-modal';
import { CreateRestaurantPartnerModal } from '@/components/restaurant/partners/create-restaurant-partner-modal';

import { columns } from '@/config/restaurant/partners';

const RestaurantPartners = () => {
  const { t } = useTranslation();
  const { partnerId } = useParams();

  const [sorting, setSorting] = useState<SortingState>([]);
  const memoizedColumns = useMemo(() => {
    return columns(t);
  }, [t]);

  const restaurant = useAuthUserRestaurant();
  const hasEditPermission = restaurant.role !== RESTAURANT_USER_ROLE.EMPLOYEE;

  const { data, isLoading, isError } = useGetChildRestaurantsQuery(
    restaurant.id,
  );

  return (
    <>
      <Helmet>
        <title>{t('partner.title')}</title>
      </Helmet>
      <div>
        <div className="flex items-center">
          <h1 className="text-lg font-semibold md:text-2xl">
            {t('partner.title')}
          </h1>
        </div>
        <div className="flex flex-col gap-3">
          <div className="self-end">
            {restaurant.role === RESTAURANT_USER_ROLE.OWNER && (
              <Link
                to="/restaurant/partners/create"
                className={buttonVariants({ variant: 'default' })}
              >
                {t('partner.create')}
              </Link>
            )}
          </div>

          <DataTable
            data={data}
            sorting={sorting}
            setSorting={setSorting}
            columns={memoizedColumns}
            isError={isError}
            isLoading={isLoading}
            columnVisibility={{
              edit: hasEditPermission,
            }}
          />
        </div>
      </div>
      <CreateRestaurantPartnerModal />
      {Boolean(partnerId) && <EditRestaurantPartnerModal key={partnerId} />}
    </>
  );
};

export default RestaurantPartners;
