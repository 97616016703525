import { cn } from '@/utils/helpers';
import { CSSProperties, ReactNode } from 'react';
import { CropperFade, CropperRef } from 'react-advanced-cropper';

export interface CropperWrapperProps<CropperRef = unknown> {
  children?: ReactNode;
  cropper: CropperRef;
  className?: string;
  style?: CSSProperties;
}

const CropperWrapper = ({
  cropper,
  className,
  children,
}: CropperWrapperProps<CropperRef>) => {
  return (
    <div
      className={cn(
        'max-h-full w-full min-w-0 cursor-grab overflow-hidden bg-primary-foregrounds active:cursor-grabbing md:bg-system-surface-container-low',
        className,
      )}
    >
      <CropperFade className="min-h-0 flex-grow" visible={cropper.isLoaded()}>
        {children}
      </CropperFade>
    </div>
  );
};

export default CropperWrapper;
