import { useState } from 'react';
import deepEqual from 'deep-equal';
import { addDays, format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { SortingState } from '@tanstack/react-table';
import {
  useAuthUserCompany,
  useGetCompanyOrdersBySingleEmployeeReportQuery,
} from '@durma-soft/gros-sdk';
import {
  useMatch,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { Modal } from '@/components/shared/modal';
import { DataTable } from '@/components/shared/data-table';
import { Skeleton } from '@/components/shared/shadcn-ui/skeleton';
import { DatePickerWithRange } from '@/components/shared/date-picker-with-range';

import { columns } from '@/config/restaurant/reports/orders-by-single-employee';

const DAY_AFTER_TODAY = 1;
const SKIP_DAYS = 7;

export const CompanyOrdersByEmployeeModal = () => {
  const { employeeId } = useParams();

  if (!employeeId) {
    throw new Error('Employee ID is required for its details');
  }

  const { t } = useTranslation();
  const navigate = useNavigate();
  const company = useAuthUserCompany();

  const [searchParams] = useSearchParams();
  const [sorting, setSorting] = useState<SortingState>([]);

  const [dateRange, setDateRange] = useState({
    from: format(
      searchParams.get('from') || addDays(Date.now(), DAY_AFTER_TODAY),
      'yyyy-MM-dd',
    ),
    to: format(
      searchParams.get('to') || addDays(Date.now(), SKIP_DAYS),
      'yyyy-MM-dd',
    ),
  });

  const isModalOpen = Boolean(
    useMatch({
      path: `/company/reports/customer-orders/single-employee/:employeeId`,
    }),
  );
  const closeModal = () => {
    navigate(`/company/reports/customer-orders?${searchParams.toString()}`, {
      replace: true,
    });
  };

  const { data, isLoading, isSuccess } =
    useGetCompanyOrdersBySingleEmployeeReportQuery({
      company_id: company.id,
      employee_id: Number(employeeId),
      start_date: dateRange.from,
      end_date: dateRange.to,
    });

  return (
    <Modal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={t('reports.orders-by-employee.title')}
      className="sm:max-w-[calc(100%-4rem)] lg:max-w-[768px] xl:max-w-[1024px] overflow-hidden flex flex-col gap-6 pb-0"
    >
      <DatePickerWithRange
        date={{
          from: new Date(dateRange.from),
          to: new Date(dateRange.to),
        }}
        setDate={([from, to]) =>
          setDateRange((prevState) => {
            return {
              ...prevState,
              from,
              to,
            };
          })
        }
        className="self-start"
      />
      {isLoading && (
        <>
          <Skeleton className="w-1/2  h-10" />
          <Skeleton className="w-full h-40" />
        </>
      )}
      {!isLoading && deepEqual(data, {}) && (
        <p className="mx-auto">{t('common.no-results')}</p>
      )}
      {!isLoading && isSuccess && (
        <>
          <h3 className="text-xl font-semibold">
            {data.first_name + ' ' + data.last_name}
          </h3>
          <div className="flex-1 overflow-y-auto pb-6 relative">
            <DataTable
              data={data.orders}
              columns={columns(t)}
              isLoading={isLoading}
              sorting={sorting}
              setSorting={setSorting}
            />
          </div>
        </>
      )}
    </Modal>
  );
};
