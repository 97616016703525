import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FixedCropperRef } from 'react-advanced-cropper';
import { useMatch, useNavigate } from 'react-router-dom';

import { Modal } from '@/components/shared/modal';
import { CreateRestaurantAdForm } from '@/components/restaurant/ad/create-restaurant-ad-form';

export const CreateRestaurantAdModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const closeModal = () => navigate('/restaurant/ads', { replace: true });

  const isModalOpen = Boolean(useMatch({ path: '/restaurant/ads/create' }));

  const cropperRef = useRef<FixedCropperRef>();

  const handleRefresh = () => {
    cropperRef.current?.refresh();
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={t('ads.create')}
      description={t('ads.create-details')}
      className="overflow-hidden flex flex-col"
      onAnimationEnd={handleRefresh}
      onTransitionEnd={handleRefresh}
    >
      <CreateRestaurantAdForm closeModal={closeModal} />
    </Modal>
  );
};
