import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { SortingState } from '@tanstack/react-table';
import {
  COMPANY_USER_ROLE,
  useAuthUserCompany,
  useGetCompanyEmployeesQuery,
} from '@durma-soft/gros-sdk';

import { PrintButton } from '@/components/shared/print-button';
import { SearchInput } from '@/components/shared/search-input';
import { buttonVariants } from '@/components/shared/shadcn-ui/button';
import { DataTable, DataTableHTML } from '@/components/shared/data-table';
import { EditCompanyEmployeeModal } from '@/components/company/employees/edit-company-employee-modal';
import { CreateCompanyEmployeeModal } from '@/components/company/employees/create-company-employee-modal';

import { useDebouncedSearch } from '@/hooks/use-debounced-search';

import { columns, printColumns } from '@/config/company/employees';

import print from '@/lib/print/print';
import { cn } from '@/utils/helpers';

const CompanyEmployees = () => {
  const { t } = useTranslation();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  const company = useAuthUserCompany();
  const hasEditPermission =
    company.role === COMPANY_USER_ROLE.MANAGER ||
    company.role === COMPANY_USER_ROLE.OWNER;

  const { employeeId } = useParams();

  const [memoizedColumns, memoizedPrintColumns] = useMemo(() => {
    return [columns(t), printColumns(t)];
  }, [t]);

  const { searchInput, debouncedSearchInput, handleChange } =
    useDebouncedSearch();

  const { data, isLoading, isPlaceholderData } = useGetCompanyEmployeesQuery({
    company_id: company.id,
    q: debouncedSearchInput,
    page: pagination.pageIndex + 1,
    page_size: pagination.pageSize,
    sort_by: sorting[0]?.id,
    sort_order: sorting[0]?.desc ? 'desc' : 'asc',
  });

  return (
    <>
      <Helmet>
        <title>{t('employee.title')}</title>
      </Helmet>

      <div>
        <div className="flex items-center">
          <h1 className="text-lg font-semibold md:text-2xl">
            {t('employee.title')}
          </h1>
        </div>
        <div
          className={cn(
            'flex flex-col gap-3',
            isPlaceholderData && 'opacity-50',
          )}
        >
          <div className="self-end flex gap-2">
            <PrintButton
              disabled={isLoading}
              variant="outline"
              onClick={() =>
                print({
                  content: (
                    <DataTableHTML
                      columns={memoizedPrintColumns}
                      data={data?.data}
                      pagination={pagination}
                    />
                  ),
                })
              }
            />
            <Link
              to={`/company/employees/create`}
              className={buttonVariants({ variant: 'default' })}
            >
              {t('employee.create')}
            </Link>
          </div>
          <div className={cn('flex flex-col gap-3')}>
            <SearchInput
              className="self-start p-0"
              placeholder={t('common.search-placeholder')}
              searchInput={searchInput}
              handleChange={(event) => {
                handleChange(event);
                setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
              }}
            />
            <DataTable
              serverSideSorting
              sorting={sorting}
              setSorting={setSorting}
              columns={memoizedColumns}
              data={data?.data}
              pageCount={data?.total_pages}
              pagination={pagination}
              isLoading={isLoading}
              setPagination={setPagination}
              columnVisibility={{
                edit: hasEditPermission,
              }}
            />
          </div>
        </div>
      </div>
      <CreateCompanyEmployeeModal />
      {Boolean(employeeId) && <EditCompanyEmployeeModal key={employeeId} />}
    </>
  );
};

export default CompanyEmployees;
