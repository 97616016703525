/* eslint-disable */
import { toast } from 'sonner';
import { useState } from 'react';
import { Pencil, Trash2 } from 'lucide-react';
import { ColumnDef } from '@tanstack/react-table';
import { RestaurantEmployee } from '@durma-soft/gros-sdk/dist/types/restaurants';
import {
  useAuthUser,
  useAuthUserRestaurant,
  useDeleteRestaurantEmployeeMutation,
  useEditRestaurantEmployeeMutation,
  ValidationError,
} from '@durma-soft/gros-sdk';

import { Link } from '@/components/shared/link';
import { Button } from '@/components/shared/shadcn-ui/button';
import { Switch } from '@/components/shared/shadcn-ui/switch';
import { ConfirmationModal } from '@/components/shared/confirmation-modal';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/shared/shadcn-ui/tooltip';

import { RESTAURANT_EMPLOYEE_ROLE } from '@/config/global/roles';

import { TType } from '@/types/general';

export const columns = (t: TType): ColumnDef<RestaurantEmployee>[] => [
  {
    accessorKey: 'ordinal_number',
    enableSorting: false,
    header: t('common.order-number'),
    cell: (cellData) => {
      const page = cellData.table.getState().pagination;
      const rowIndex = cellData.row.index;
      const ordinalNumber = page.pageIndex * page.pageSize + rowIndex + 1;

      return <>{ordinalNumber}</>;
    },
  },
  {
    accessorKey: 'first_name',
    header: t('common.first-name'),
  },
  {
    accessorKey: 'last_name',
    header: t('common.last-name'),
  },
  {
    accessorKey: 'username',
    header: t('common.username'),
  },
  {
    accessorKey: 'role',
    header: t('roles.title'),
    cell: (cellData) => {
      const { role } = cellData.row.original;
      return <>{RESTAURANT_EMPLOYEE_ROLE(t)[role]}</>;
    },
  },
  {
    accessorKey: 'is_active',
    enableSorting: false,
    header: t('restaurant-employee.is-active'),
    cell: (cellData) => {
      const { user } = useAuthUser();
      const restaurant = useAuthUserRestaurant();
      const editRestaurantEmployee = useEditRestaurantEmployeeMutation();

      const handleActiveStatusChange = async (checked: boolean) => {
        if (editRestaurantEmployee.isPending) return;
        try {
          await editRestaurantEmployee.mutateAsync({
            restaurant_id: restaurant.id,
            employee_id: cellData.row.original.id,
            is_active: checked,
          });
          toast.success(t('restaurant-employee.edit-status-success-msg'));
        } catch (error) {
          if (error instanceof ValidationError) {
            return toast.error(error.message);
          }
          toast.error(t('restaurant-employee.edit-edit-error-msg'));
        }
      };

      return (
        <Switch
          disabled={
            cellData.row.original.id === user.id ||
            editRestaurantEmployee.isPending
          }
          checked={cellData.row.original.is_active}
          onCheckedChange={handleActiveStatusChange}
        />
      );
    },
  },
  {
    accessorKey: 'edit',
    enableSorting: false,
    header: '',
    cell: (cellData) => {
      const restaurant = useAuthUserRestaurant();
      const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
        useState(false);

      const { isPending, mutateAsync } = useDeleteRestaurantEmployeeMutation();

      const deleteEmployee = async () => {
        try {
          await mutateAsync({
            restaurant_id: restaurant.id,
            employee_id: cellData.row.original.id,
          });
          toast.success(t('restaurant-employee.delete-success-msg'));
        } catch (error) {
          toast.error(t('restaurant-employee.delete-error-msg'));
        } finally {
          setIsConfirmationModalOpen(false);
        }
      };
      return (
        <>
          <TooltipProvider delayDuration={100}>
            <div className="flex items-center justify-center gap-5">
              {/* Edit */}
              <Tooltip>
                <TooltipTrigger asChild>
                  <Link
                    replace
                    to={`/restaurant/employees/edit/${cellData.row.original.id}`}
                    className="flex items-center justify-center text-gray-700 "
                  >
                    <Pencil className="w-5 h-5" />
                  </Link>
                </TooltipTrigger>
                <TooltipContent>
                  {t('restaurant-employee.edit-details')}
                </TooltipContent>
              </Tooltip>
              {/* Delete */}
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    className="flex items-center justify-center text-gray-700 p-0"
                    onClick={() => setIsConfirmationModalOpen(true)}
                  >
                    <Trash2 className="w-5 h-5" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>{t('common.delete')}</TooltipContent>
              </Tooltip>
            </div>
          </TooltipProvider>
          <ConfirmationModal
            isLoading={isPending}
            isModalOpen={isConfirmationModalOpen}
            confirmationText={t('common.delete')}
            title={t('restaurant-employee.delete')}
            description={t('restaurant-employee.delete-description', {
              employee: (
                cellData.row.original.first_name +
                cellData.row.original.last_name
              ).toLowerCase(),
            })}
            onConfirm={deleteEmployee}
            onCancel={() => setIsConfirmationModalOpen(false)}
            closeModal={() => setIsConfirmationModalOpen(false)}
          />
        </>
      );
    },
  },
];

export const printColumns = (t: TType): ColumnDef<RestaurantEmployee>[] => [
  {
    accessorKey: 'ordinal_number',
    header: t('common.order-number'),
    cell: (cellData) => {
      const page = cellData.table.getState().pagination;
      const rowIndex = cellData.row.index;
      const ordinalNumber = page.pageIndex * page.pageSize + rowIndex + 1;

      return <>{ordinalNumber}</>;
    },
  },
  {
    accessorKey: 'first_name',
    header: t('common.first-name'),
  },
  {
    accessorKey: 'last_name',
    header: t('common.last-name'),
  },
  {
    accessorKey: 'username',
    header: t('common.username'),
  },
  {
    accessorKey: 'role',
    header: t('roles.title'),
    cell: (cellData) => {
      const { role } = cellData.row.original;
      return <>{RESTAURANT_EMPLOYEE_ROLE(t)[role]}</>;
    },
  },
];
