import { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LocationType } from '@durma-soft/gros-sdk';
import { SortingState } from '@tanstack/react-table';
import { NumberOfOrdersPerEmployeeReportUser } from '@durma-soft/gros-sdk/dist/types/reports';

import { DataTable, DataTableHTML } from '@/components/shared/data-table';

import { columns } from '@/config/restaurant/reports/number-of-customer-orders';

interface NumberOfCustomerOrdersProps {
  forPrint?: boolean;
  locationType: LocationType;
  sorting?: SortingState;
  setSorting?: Dispatch<SetStateAction<SortingState>>;
  isTableSyncReference?: boolean;
  users: NumberOfOrdersPerEmployeeReportUser[];
}

export const NumberOfCustomerOrders = ({
  users,
  sorting,
  setSorting,
  locationType,
  forPrint = false,
  isTableSyncReference,
}: NumberOfCustomerOrdersProps) => {
  const { t } = useTranslation();

  const memoizedColumns = useMemo(
    () => columns(t, locationType),
    [t, locationType],
  );

  return (
    <div>
      {!forPrint ? (
        <DataTable
          data={users}
          sorting={sorting}
          setSorting={setSorting}
          columns={memoizedColumns}
          isSyncReference={isTableSyncReference}
        />
      ) : (
        <DataTableHTML
          data={users}
          sorting={sorting}
          setSorting={setSorting}
          columns={memoizedColumns}
          columnVisibility={{ details: false }}
        />
      )}
    </div>
  );
};
