import { ColumnDef } from '@tanstack/react-table';
import { OrdersByFoodReportRowFood } from '@durma-soft/gros-sdk/dist/types/reports';

import { TType } from '@/types/general';

export const columns = (t: TType): ColumnDef<OrdersByFoodReportRowFood>[] => [
  {
    header: t('common.order-number'),
    enableSorting: false,
    cell: ({ row: { index } }) => Number(index + 1),
  },
  {
    accessorKey: 'name',
    header: t('common.name'),
  },
  {
    header: t('shifts.first'),
    cell: (cellData) => {
      const { shift_1_users: users, shift_1_quantity: total } =
        cellData.row.original;
      return (
        <>
          {total ? <strong>({total}) </strong> : '/'}
          {users.map((user, i) => {
            return (
              <span key={user.id}>
                {`${user.first_name} ${user.last_name}`}{' '}
                <span className="font-medium">({user.quantity}x)</span>
                {i !== users.length - 1 && ', '}
              </span>
            );
          })}
        </>
      );
    },
    footer: ({ table }) => {
      const totalShift1Quantity = table
        .getFilteredRowModel()
        .rows.reduce((total, row) => total + row.original.shift_1_quantity, 0);

      return totalShift1Quantity;
    },
  },
  {
    header: t('shifts.second'),
    cell: (cellData) => {
      const { shift_2_users: users, shift_2_quantity: total } =
        cellData.row.original;
      return (
        <>
          {total ? <strong>({total}) </strong> : '/'}
          {users.map((user, i) => {
            return (
              <span key={user.id}>
                {`${user.first_name} ${user.last_name}`}{' '}
                <span className="font-medium">({user.quantity}x)</span>
                {i !== users.length - 1 && ', '}
              </span>
            );
          })}
        </>
      );
    },
    footer: ({ table }) => {
      const totalShift2Quantity = table
        .getFilteredRowModel()
        .rows.reduce((total, row) => total + row.original.shift_2_quantity, 0);

      return totalShift2Quantity;
    },
  },
  {
    header: t('shifts.third'),
    cell: (cellData) => {
      const { shift_3_users: users, shift_3_quantity: total } =
        cellData.row.original;
      return (
        <>
          {total ? <strong>({total}) </strong> : '/'}
          {users.map((user, i) => {
            return (
              <span key={user.id}>
                {`${user.first_name} ${user.last_name}`}{' '}
                <span className="font-medium">({user.quantity}x)</span>
                {i !== users.length - 1 && ', '}
              </span>
            );
          })}
        </>
      );
    },
    footer: ({ table }) => {
      const totalShift3Quantity = table
        .getFilteredRowModel()
        .rows.reduce((total, row) => total + row.original.shift_3_quantity, 0);

      return totalShift3Quantity;
    },
  },
];
