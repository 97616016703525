import {
  Size,
  ratio,
  copyState,
  applyScale,
  CoreSettings,
  CropperState,
  PostprocessAction,
  isInitializedState,
  moveToPositionRestrictions,
  mergePositionRestrictions,
  coordinatesToPositionRestrictions,
  getPositionRestrictions,
  getAreaPositionRestrictions,
  diff,
  getCenter,
  applyMove,
  fitToSizeRestrictions,
  getAreaSizeRestrictions,
} from 'react-advanced-cropper';

export function hybridStencilAutoZoomAlgorithm(
  state: CropperState,
  settings: CoreSettings,
): CropperState {
  if (isInitializedState(state)) {
    const result = copyState(state);

    const isFullImageSelected =
      Math.abs(result.coordinates.width - result.imageSize.width) < 1 &&
      Math.abs(result.coordinates.height - result.imageSize.height) < 1;

    const multiplyFactor = isFullImageSelected ? 0.9 : 0.8;

    // Checks that coordinates has the same ratio that coordinates:
    const stencil: Size = {
      width: 0,
      height: 0,
    };

    if (ratio(result.boundary) > ratio(result.coordinates)) {
      stencil.height = result.boundary.height * multiplyFactor;
      stencil.width = stencil.height * ratio(result.coordinates);
    } else {
      stencil.width = result.boundary.width * multiplyFactor;
      stencil.height = stencil.width * ratio(result.coordinates);
    }

    // First of all try to resize visible area as much as possible:
    result.visibleArea = applyScale(
      result.visibleArea,
      (result.coordinates.width * result.boundary.width) /
        (result.visibleArea.width * stencil.width),
    );

    // Check that visible area doesn't break the area restrictions:
    const scale = fitToSizeRestrictions(
      result.visibleArea,
      getAreaSizeRestrictions(result, settings),
    );

    result.visibleArea = applyScale(result.visibleArea, scale);

    if (scale !== 1) {
      stencil.height /= scale;
      stencil.width /= scale;
    }

    result.visibleArea = applyMove(
      result.visibleArea,
      diff(getCenter(result.coordinates), getCenter(result.visibleArea)),
    );

    // Center stencil in visible area:
    result.visibleArea = moveToPositionRestrictions(
      result.visibleArea,
      getAreaPositionRestrictions(result, settings),
    );

    result.coordinates = moveToPositionRestrictions(
      result.coordinates,
      mergePositionRestrictions(
        coordinatesToPositionRestrictions(result.visibleArea),
        getPositionRestrictions(result, settings),
      ),
    );

    return result;
  }
  return state;
}

export function hybridStencilAutoZoom(
  state: CropperState,
  settings: CoreSettings,
  action: PostprocessAction,
): CropperState {
  if (action.immediately) {
    return hybridStencilAutoZoomAlgorithm(state, settings);
  }
  return state;
}

export function getStencilConfig(aspectRatio?: number | null) {
  if (!aspectRatio) {
    return {
      handlers: true,
      resizable: true,
    };
  }

  return {
    aspectRatio,
    handlers: false,
    resizable: false,
  };
}

export const getBlobFromCanvas = async (canvas: HTMLCanvasElement) => {
  if (canvas.height === 0) canvas.height = 1;
  if (canvas.width === 0) canvas.width = 1;
  return new Promise<Blob | null>((resolve) => {
    canvas.toBlob(
      (blob) => {
        resolve(blob);
      },
      'image/jpeg',
      0.8,
    );
  });
};
