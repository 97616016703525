import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SortingState } from '@tanstack/react-table';
import { useAuthUserRestaurant, useGetAdsQuery } from '@durma-soft/gros-sdk';

import { Link } from '@/components/shared/link';
import { DataTable } from '@/components/shared/data-table';
import { buttonVariants } from '@/components/shared/shadcn-ui/button';
import { EditRestaurantAdModal } from '@/components/restaurant/ad/edit-restaurant-ad-modal';
import { CreateRestaurantAdModal } from '@/components/restaurant/ad/create-restaurant-ad-modal';

import { columns } from '@/config/restaurant/ads';

const RestaurantAds = () => {
  const { adId } = useParams();
  const { t } = useTranslation();
  const memoizedColumns = useMemo(() => {
    return columns(t);
  }, [t]);

  const restaurant = useAuthUserRestaurant();
  const [sorting, setSorting] = useState<SortingState>([]);

  const { data, isLoading } = useGetAdsQuery(restaurant.id);

  return (
    <>
      <Helmet>
        <title>{t('ads.title')}</title>
      </Helmet>
      <div>
        <div className="flex items-center">
          <h1 className="text-lg font-semibold md:text-2xl">
            {t('ads.title')}
          </h1>
        </div>
        <div className="flex flex-col gap-3">
          <div className="self-end">
            <Link
              to="/restaurant/ads/create"
              className={buttonVariants({ variant: 'default' })}
            >
              {t('ads.create')}
            </Link>
          </div>
          <DataTable
            columns={memoizedColumns}
            data={data || []}
            isLoading={isLoading}
            sorting={sorting}
            setSorting={setSorting}
          />
        </div>
      </div>
      <CreateRestaurantAdModal />
      {Boolean(adId) && <EditRestaurantAdModal key={adId} />}
    </>
  );
};

export default RestaurantAds;
