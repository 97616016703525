import { useMemo, useState } from 'react';
import { format, isEqual } from 'date-fns';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import {
  useAuthUserRestaurant,
  useGetAllRestaurantCompaniesQuery,
  useGetFoodReportQuery,
} from '@durma-soft/gros-sdk';
import { ShortRestaurantLocation } from '@durma-soft/gros-sdk/dist/types/restaurants';

import { useDatePickerRangeState } from '@/hooks/use-date-picker-range-state';

import { PrintButton } from '@/components/shared/print-button';
import { Skeleton } from '@/components/shared/shadcn-ui/skeleton';
import { LastUpdatedInfo } from '@/components/shared/last-updated-info';
import { DataTable, DataTableHTML } from '@/components/shared/data-table';
import { DatePickerWithRange } from '@/components/shared/date-picker-with-range';
import { SelectCompanyLocations } from '@/components/shared/select-company-locations';

import print from '@/lib/print/print';

import { columns } from '@/config/restaurant/reports/foods';
import { SortingState } from '@tanstack/react-table';
import { repeat } from '@/utils/helpers';

const FoodsReport = () => {
  const { t } = useTranslation();
  const memoizedColumns = useMemo(() => columns(t), [t]);

  const { from, to, setDateRange } = useDatePickerRangeState();

  const restaurant = useAuthUserRestaurant();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [companyLocations, setCompanyLocations] = useState<
    ShortRestaurantLocation[]
  >([]);

  const {
    data: companiesAndGroups,
    isLoading: isLoadingCompanies,
    isSuccess: isSuccessCompanies,
  } = useGetAllRestaurantCompaniesQuery(restaurant.id);

  const {
    data,
    isLoading: isLoadingFood,
    isSuccess: isSuccessFood,
    dataUpdatedAt,
    isRefetching,
    refetch,
  } = useGetFoodReportQuery({
    restaurant_id: restaurant.id,
    start_date: from,
    end_date: to,
    company_location_ids: companyLocations.length
      ? companyLocations.map(({ id }) => id)
      : [],
  });

  const handleRefetch = async () => {
    await refetch();
  };

  return (
    <>
      <Helmet>
        <title>{t('reports.foods.title')}</title>
      </Helmet>
      <div className="flex items-center justify-between">
        <h1 className="text-lg font-semibold md:text-2xl">
          {t('reports.foods.title')}
        </h1>
        {(isLoadingCompanies || isLoadingFood) && (
          <Skeleton className="h-10 w-52" />
        )}
        {isSuccessCompanies && isSuccessFood && (
          <LastUpdatedInfo
            isRefetching={isRefetching}
            dataUpdatedAt={dataUpdatedAt}
            handleRefetch={handleRefetch}
          />
        )}
      </div>
      <div className="flex flex-col gap-3 my-5 lg:flex-row ">
        <div className="flex-1 flex flex-col gap-3 lg:flex-row ">
          <div className="self-start">
            <DatePickerWithRange
              date={{ from: new Date(from), to: new Date(to) }}
              setDate={([from, to]) => setDateRange({ from, to })}
            />
          </div>
          <div>
            <SelectCompanyLocations
              selectAllByDefault
              companies={isSuccessCompanies ? companiesAndGroups.companies : []}
              groups={isSuccessCompanies ? companiesAndGroups.groups : []}
              isLoading={isLoadingCompanies}
              selectedCompanyLocations={companyLocations}
              onUpdateCompanyLocations={setCompanyLocations}
            />
          </div>
        </div>
        <div>
          <PrintButton
            disabled={isLoadingCompanies || isLoadingFood}
            onClick={() =>
              print({
                content: (
                  <div>
                    <div>
                      <div className="flex-between">
                        <h1>{t('reports.foods.title')}</h1>
                        <div className="flex-row">
                          <p>{t('common.data-updated-at')}</p>
                          <span>&nbsp;</span>
                          <p>
                            <span> {format(dataUpdatedAt, 'dd.MM.yyyy')}</span>
                            <span> {t('common.in')} </span>
                            <span> {format(dataUpdatedAt, 'HH:mm:ss')}</span>
                          </p>
                        </div>
                      </div>
                      <div className="flex-between">
                        <p>
                          {format(from, 'dd.MM.yyyy')}
                          {!isEqual(from, to) &&
                            ` - ${format(to, 'dd.MM.yyyy')}`}
                        </p>
                      </div>
                    </div>
                    <DataTableHTML
                      columns={memoizedColumns}
                      data={data}
                      sorting={sorting}
                      setSorting={setSorting}
                    />
                  </div>
                ),
              })
            }
          />
        </div>
      </div>
      <div className="flex flex-col gap-3">
        {(isLoadingCompanies || isLoadingFood) && (
          <div className="flex flex-col gap-10">
            {repeat(5).map((index) => (
              <div key={index}>
                <Skeleton className="w-full rounded-md h-60" />
              </div>
            ))}
          </div>
        )}
        {!isLoadingCompanies &&
          !isLoadingFood &&
          companyLocations.length === 0 && (
            <p className="self-center">{t('reports.orders.choose-location')}</p>
          )}

        {companyLocations.length !== 0 && (
          <DataTable
            columns={memoizedColumns}
            data={data}
            isLoading={isLoadingFood}
            sorting={sorting}
            setSorting={setSorting}
          />
        )}
      </div>
    </>
  );
};

export default FoodsReport;
