/* eslint-disable */
import { toast } from 'sonner';
import { useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { CircleMinus, Pencil, Printer, Trash2, Utensils } from 'lucide-react';
import {
  RestaurantFood,
  RestaurantFoodWithIngredients,
} from '@durma-soft/gros-sdk/dist/types/restaurants';
import {
  httpClient,
  RESTAURANT_USER_ROLE,
  useAuthUserRestaurant,
  useDeleteFoodMutation,
} from '@durma-soft/gros-sdk';

import printFoodWithIngredients from '@/lib/print/print-food-with-ingredients';

import { Link } from '@/components/shared/link';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/shared/shadcn-ui/tooltip';

import { Button } from '@/components/shared/shadcn-ui/button';
import { ConfirmationModal } from '@/components/shared/confirmation-modal';

import { TType } from '@/types/general';
import { FoodAttribute } from '@/types/foods';

export const columns = (t: TType): ColumnDef<RestaurantFood>[] => [
  {
    accessorKey: 'picture',
    enableSorting: false,
    header: t('common.image'),
    cell: (cellData) => {
      const { picture } = cellData.row.original;

      if (!picture) {
        return (
          <div className="inline-flex items-center justify-center w-12 h-12 border rounded-md border-border">
            <Utensils className="w-6 h-6" />
          </div>
        );
      }
      return (
        <img
          src={picture}
          alt=""
          width="128"
          height="128"
          className="object-cover object-center w-12 h-12 rounded-md"
        />
      );
    },
  },
  {
    accessorKey: 'name',
    header: t('common.name'),
  },
  {
    accessorKey: 'actions',
    enableSorting: false,
    header: '',
    cell: (cellData) => {
      const restaurant = useAuthUserRestaurant();
      const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
        useState(false);

      const { isPending, mutateAsync } = useDeleteFoodMutation();

      const deleteFood = async () => {
        try {
          await mutateAsync({
            restaurant_id: restaurant.id,
            food_id: cellData.row.original.id,
          });
          toast.success(t('food.delete-success-msg'));
        } catch (error) {
          toast.error(t('food.delete-error-msg'));
        } finally {
          setIsConfirmationModalOpen(false);
        }
      };

      return (
        <>
          <TooltipProvider delayDuration={100}>
            <div className="flex items-center justify-center gap-5">
              {/* Print */}
              <Tooltip>
                <TooltipTrigger asChild>
                  <button
                    onClick={async () => {
                      try {
                        const printWindow = window.open('', '_blank');
                        if (!printWindow)
                          throw new Error('Failed to open print window');
                        const { data } =
                          await httpClient.get<RestaurantFoodWithIngredients>(
                            `/restaurants/${restaurant.id}/food/${cellData.row.original.id}`,
                          );
                        printFoodWithIngredients(printWindow, data, t);
                      } catch (error) {
                        toast.error(t('common.error'));
                      }
                    }}
                    type="button"
                    className="flex items-center justify-center text-gray-700 "
                  >
                    <Printer className="w-5 h-5" />
                  </button>
                </TooltipTrigger>
                <TooltipContent>{t('food.print')}</TooltipContent>
              </Tooltip>
              {restaurant.role === RESTAURANT_USER_ROLE.OWNER && (
                <>
                  {/* Edit */}
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Link
                        replace
                        to={`/restaurant/foods/edit/${cellData.row.original.id}`}
                        className="flex items-center justify-center text-gray-700 "
                      >
                        <Pencil className="w-5 h-5" />
                      </Link>
                    </TooltipTrigger>
                    <TooltipContent>{t('food.edit-details')}</TooltipContent>
                  </Tooltip>
                  {/* Subtract */}
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Link
                        replace
                        to={`/restaurant/foods/subtract/${cellData.row.original.id}`}
                        className="flex items-center justify-center text-gray-700 "
                      >
                        <CircleMinus className="w-5 h-5" />
                      </Link>
                    </TooltipTrigger>
                    <TooltipContent>
                      {t('food.subtract-from-inventory')}
                    </TooltipContent>
                  </Tooltip>
                  {/* Delete */}
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant="ghost"
                        className="flex items-center justify-center text-gray-700 p-0"
                        onClick={() => setIsConfirmationModalOpen(true)}
                      >
                        <Trash2 className="w-5 h-5" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>{t('common.delete')}</TooltipContent>
                  </Tooltip>
                </>
              )}
            </div>
          </TooltipProvider>
          <ConfirmationModal
            isLoading={isPending}
            isModalOpen={isConfirmationModalOpen}
            confirmationText={t('common.delete')}
            title={t('food.delete')}
            description={t('food.delete-description', {
              food: cellData.row.original.name.toLowerCase(),
            })}
            onConfirm={deleteFood}
            onCancel={() => setIsConfirmationModalOpen(false)}
            closeModal={() => setIsConfirmationModalOpen(false)}
          />
        </>
      );
    },
  },
];

export const foodAttributes = (t: TType): FoodAttribute[] => [
  {
    id: 'new',
    label: t('food.attributes.new'),
  },
  {
    id: 'fasting',
    label: t('food.attributes.fasting'),
  },
  {
    id: 'halal',
    label: t('food.attributes.halal'),
  },
  {
    id: 'vegan',
    label: t('food.attributes.vegan'),
  },
  {
    id: 'vegetarian',
    label: t('food.attributes.vegetarian'),
  },
];
