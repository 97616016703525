/* eslint-disable */
import { useParams } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import { Pencil, UsersRound } from 'lucide-react';
import {
  SHIFT,
  RESTAURANT_USER_ROLE,
  useAuthUserRestaurant,
} from '@durma-soft/gros-sdk';
import { CompanyLocationWithEmployeesCount } from '@durma-soft/gros-sdk/dist/types/company';

import { Link } from '@/components/shared/link';
import { Badge } from '@/components/shared/shadcn-ui/badge';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/shared/shadcn-ui/tooltip';

import { TType } from '@/types/general';
import { LocationDeliveryType } from '@/types/companies';

export const columns = (
  t: TType,
): ColumnDef<CompanyLocationWithEmployeesCount>[] => [
  {
    accessorKey: 'name',
    enableSorting: false,
    header: t('common.name'),
  },
  {
    accessorKey: 'address',
    enableSorting: false,
    header: t('common.address'),
    cell: (cellData) => {
      return (
        <ul>
          <li>{cellData.row.original.street}</li>
          <li>{cellData.row.original.city}</li>
          <li>{cellData.row.original.zip}</li>
          <li>{cellData.row.original.state}</li>
          <li>{cellData.row.original.country}</li>
        </ul>
      );
    },
  },
  {
    accessorKey: 'shifts',
    enableSorting: false,
    header: t('common.shifts'),
    cell: (cellData) => {
      const { shift_1, shift_2, shift_3 } = cellData.row.original;
      return (
        <div className="flex gap-2">
          {shift_1 && <Badge className="justify-center">{SHIFT.FIRST}</Badge>}
          {shift_2 && <Badge className="justify-center">{SHIFT.SECOND}</Badge>}
          {shift_3 && <Badge className="justify-center">{SHIFT.THIRD}</Badge>}
        </div>
      );
    },
  },
  {
    accessorKey: 'location_type',
    enableSorting: false,
    header: t('common.location-type'),
    cell: (cellData) => (
      <>{t(`location-type.${cellData.row.original.location_type}`)}</>
    ),
  },
  {
    accessorKey: 'menu_type',
    enableSorting: false,
    header: t('common.menu-type'),
    cell: (cellData) => <>{cellData.row.original?.menu_type?.name}</>,
  },
  {
    accessorKey: 'employees_count',
    enableSorting: false,
    header: t('employee.count'),
    cell: (cellData) => {
      const { companyId } = useParams();
      return (
        <div className="w-full">
          <Link
            to={`/restaurant/companies/${companyId}/locations/${cellData.row.original.id}/employees`}
          >
            <Badge className="bg-muted border-gray-300 text-gray-700 hover:bg-gray-200 transition-all duration-300">
              {cellData.row.original.employees_count}
            </Badge>
          </Link>
        </div>
      );
    },
  },
  {
    id: 'actions',
    enableHiding: false,
    cell: (cellData) => {
      const { companyId } = useParams();
      const restaurant = useAuthUserRestaurant();
      const hasEditPermission =
        restaurant.role !== RESTAURANT_USER_ROLE.EMPLOYEE;
      return (
        <TooltipProvider delayDuration={100}>
          <div className="flex items-center justify-center gap-4">
            {/* Company location employees */}
            <Tooltip>
              <TooltipTrigger>
                <Link
                  to={`/restaurant/companies/${companyId}/locations/${cellData.row.original.id}/employees`}
                  className="flex items-center justify-center text-gray-700 "
                >
                  <UsersRound className="w-5 h-5" />
                </Link>
              </TooltipTrigger>
              <TooltipContent>
                {t('company-locations.employees')}
              </TooltipContent>
            </Tooltip>
            {/* Edit company location */}
            {hasEditPermission && (
              <Tooltip>
                <TooltipTrigger>
                  <Link
                    replace
                    to={`/restaurant/companies/${companyId}/locations/edit/${cellData.row.original.id}`}
                    className="flex items-center justify-center text-gray-700 "
                  >
                    <Pencil className="w-5 h-5" />
                  </Link>
                  <TooltipContent>{t('company.edit-details')}</TooltipContent>
                </TooltipTrigger>
              </Tooltip>
            )}
          </div>
        </TooltipProvider>
      );
    },
  },
];

export const locationDeliveryTypes = (t: TType): LocationDeliveryType[] => [
  {
    value: 'direct',
    label: t('location-type.direct'),
  },
  {
    value: 'track',
    label: t('location-type.track'),
  },
  {
    value: 'restaurant',
    label: t('location-type.restaurant'),
  },
];
