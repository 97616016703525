/* eslint-disable */
import { toast } from 'sonner';
import { useState } from 'react';
import { Image, Pencil, Trash2 } from 'lucide-react';
import { ColumnDef } from '@tanstack/react-table';
import { Ad } from '@durma-soft/gros-sdk/dist/types/ads';
import {
  ValidationError,
  useAuthUserRestaurant,
  useDeleteAdMutation,
  useUpdateAdActiveStatusMutation,
} from '@durma-soft/gros-sdk';

import { Link } from '@/components/shared/link';
import { Button } from '@/components/shared/shadcn-ui/button';
import { Switch } from '@/components/shared/shadcn-ui/switch';
import { ConfirmationModal } from '@/components/shared/confirmation-modal';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/shared/shadcn-ui/tooltip';

import { TType } from '@/types/general';

export const columns = (t: TType): ColumnDef<Ad>[] => [
  {
    accessorKey: 'name',
    header: t('common.name'),
  },
  {
    accessorKey: 'image',
    enableSorting: false,
    header: t('common.image'),
    cell: (cellData) => {
      const { image } = cellData.row.original;

      if (!image) {
        return (
          <div className="inline-flex items-center justify-center w-[320px] h-[60px] border rounded-md border-border">
            <Image className="w-6 h-6" />
          </div>
        );
      }
      return (
        <img
          src={image}
          alt=""
          width="320"
          height="60"
          className="object-cover object-center w-[320px] h-[60px] rounded-md"
        />
      );
    },
  },
  {
    accessorKey: 'url',
    enableSorting: false,
    header: t('common.url'),
    cell: (cellData) => {
      const link = cellData.getValue() as string;
      return (
        <a className="text-blue-700" target="_blank" href={link}>
          {link}
        </a>
      );
    },
  },
  {
    accessorKey: 'is_active',
    enableSorting: false,
    header: t('ads.is-active'),
    cell: (cellData) => {
      const restaurant = useAuthUserRestaurant();

      const updateAdActiveStatusMutation = useUpdateAdActiveStatusMutation();

      const handleActiveStatusChange = async (checked: boolean) => {
        if (updateAdActiveStatusMutation.isPending) return;
        try {
          await updateAdActiveStatusMutation.mutateAsync({
            restaurant_id: restaurant.id,
            ad_id: cellData.row.original.id,
            is_active: checked,
          });
          toast.success(t('ads.edit-status-success-msg'));
        } catch (error) {
          if (error instanceof ValidationError) {
            return toast.error(error.message);
          }
          toast.error(t('ads.edit-edit-error-msg'));
        }
      };

      return (
        <Switch
          disabled={updateAdActiveStatusMutation.isPending}
          checked={cellData.row.original.is_active}
          onCheckedChange={handleActiveStatusChange}
        />
      );
    },
  },
  {
    accessorKey: 'edit',
    enableSorting: false,
    header: '',
    cell: (cellData) => {
      const restaurant = useAuthUserRestaurant();

      const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
        useState(false);

      const { isPending, mutateAsync } = useDeleteAdMutation();

      const deleteAd = async () => {
        try {
          await mutateAsync({
            restaurant_id: restaurant.id,
            ad_id: cellData.row.original.id,
          });
          toast.success(t('ads.delete-success-msg'));
        } catch (error) {
          toast.error(t('ads.delete-error-msg'));
        } finally {
          setIsConfirmationModalOpen(false);
        }
      };

      return (
        <>
          <TooltipProvider delayDuration={100}>
            <div className="flex items-center justify-center gap-5">
              {/* Edit */}
              <Tooltip>
                <TooltipTrigger asChild>
                  <Link
                    replace
                    to={`/restaurant/ads/edit/${cellData.row.original.id}`}
                    className="flex items-center justify-center text-gray-700 "
                  >
                    <Pencil className="w-5 h-5" />
                  </Link>
                </TooltipTrigger>
                <TooltipContent>{t('ads.edit-details')}</TooltipContent>
              </Tooltip>
              {/* Delete */}
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    className="flex items-center justify-center text-gray-700 p-0"
                    onClick={() => setIsConfirmationModalOpen(true)}
                  >
                    <Trash2 className="w-5 h-5" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>{t('common.delete')}</TooltipContent>
              </Tooltip>
            </div>
            <ConfirmationModal
              isLoading={isPending}
              isModalOpen={isConfirmationModalOpen}
              confirmationText={t('common.delete')}
              title={t('ads.delete')}
              description={t('ads.delete-description', {
                ad: cellData.row.original.name.toLowerCase(),
              })}
              onConfirm={deleteAd}
              onCancel={() => setIsConfirmationModalOpen(false)}
              closeModal={() => setIsConfirmationModalOpen(false)}
            />
          </TooltipProvider>
        </>
      );
    },
  },
];
