import { ReactNode } from 'react';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/shared/shadcn-ui/dialog';

import { cn, noop } from '@/utils/helpers';

interface ModalProps {
  title?: ReactNode;
  className?: string;
  children?: ReactNode;
  description?: ReactNode;
  isModalOpen?: boolean;
  closeModal?: () => unknown;
  onAnimationEnd?: () => void;
  onTransitionEnd?: () => void;
}

const Modal = ({
  title,
  className,
  description,
  children,
  isModalOpen,
  closeModal = noop,
  onAnimationEnd = noop,
  onTransitionEnd = noop,
}: ModalProps) => {
  return (
    <Dialog open={isModalOpen} onOpenChange={closeModal}>
      <DialogContent
        className={cn('sm:max-w-[475px]', className)}
        onAnimationEnd={onAnimationEnd}
        onTransitionEnd={onTransitionEnd}
      >
        <DialogHeader>
          {title && <DialogTitle>{title}</DialogTitle>}
          {description && <DialogDescription>{description}</DialogDescription>}
        </DialogHeader>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export { Modal };
