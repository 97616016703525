import { toast } from 'sonner';
import LinkifyIt from 'linkify-it';
import { useTranslation } from 'react-i18next';
import { Ad } from '@durma-soft/gros-sdk/dist/types/ads';
import { FormEvent, useId, useMemo, useRef, useState } from 'react';
import {
  useAuthUserRestaurant,
  useEditAdMutation,
  ValidationError,
} from '@durma-soft/gros-sdk';

import { useChangedObject } from '@/hooks/use-changed-object';

import { Label } from '@/components/shared/shadcn-ui/label';
import { Input } from '@/components/shared/shadcn-ui/input';
import { Button } from '@/components/shared/shadcn-ui/button';
import { DialogFooter } from '@/components/shared/shadcn-ui/dialog';
import AdCropper, { CropperHandle } from '@/components/restaurant/ad/cropper';

import { cn, noop } from '@/utils/helpers';
import { getBlobFromCanvas } from '@/utils/cropper';

import { AdFormData } from '@/types/ad';

interface EditRestaurantAdFormProps {
  ad: Ad;
  onClose?: () => unknown;
}
const linkify = new LinkifyIt();

export const EditRestaurantAdForm = ({
  ad,
  onClose = noop,
}: EditRestaurantAdFormProps) => {
  const { t } = useTranslation();
  const cropperRef = useRef<CropperHandle>(null);

  const [formData, setFormData] = useState<AdFormData>({
    name: ad.name,
    url: ad.url,
    image: null,
  });

  const uid = useId();
  const restaurant = useAuthUserRestaurant();
  const editRestaurantAd = useEditAdMutation();

  const [isChanged, changedObj] = useChangedObject(formData, ad, {
    keysToIgnore: ['image'],
  });

  const isURLValid = useMemo(() => {
    return Boolean(formData.url && linkify.match(formData.url)?.[0].url);
  }, [formData.url]);

  const isValid = useMemo(() => {
    return formData.name.length > 0 && isURLValid;
  }, [formData.name, isURLValid]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValid) return;

    let file = undefined as File | undefined;
    if (formData.image) {
      if (!cropperRef.current) return;

      const canvas = cropperRef.current.getCanvas();
      if (!canvas) return;
      file = (await getBlobFromCanvas(canvas)) as File;

      if (file === null) {
        toast.error('ads.cropping-fail');
        return;
      }
    }

    const url = linkify.match(formData.url)?.[0].url || '';

    try {
      await editRestaurantAd.mutateAsync({
        ad_id: ad.id,
        restaurant_id: restaurant.id,
        ...changedObj,
        url,
        image: file,
      });
      onClose();
      toast.success(t('ads.edit-success-msg'));
    } catch (error) {
      if (error instanceof ValidationError) {
        return toast.error(error.message);
      }
      toast.error(t('ads.edit-error.msg'));
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col flex-1 gap-6 overflow-hidden"
    >
      <div className="flex flex-col flex-1 gap-4 p-2 overflow-y-auto">
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-name'}>{t('common.name')}</Label>
          <Input
            required
            id={uid + '-name'}
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            placeholder={t('common.name-placeholder')}
          />
        </div>
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-url'}>{t('common.url')}</Label>
          <Input
            required
            id={uid + '-url'}
            value={formData.url}
            onChange={(e) => setFormData({ ...formData, url: e.target.value })}
            placeholder={t('common.url-placeholder')}
            className={cn(
              !isURLValid
                ? 'focus-visible:!ring-red-500'
                : 'focus-visible:!ring-primary',
            )}
          />
        </div>
        <div className="flex flex-col gap-2">
          <Label>{t('common.current-image')}</Label>
          <div className="w-full rounded-md overflow-hidden">
            <img
              src={ad.image}
              className="aspect-[320/60] w-full object-cover"
              height={60}
              width={320}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <AdCropper
            ref={cropperRef}
            img={formData.image}
            actionMessage={t('common.change-image')}
            setImg={(img) => {
              setFormData((prevState) => {
                return {
                  ...prevState,
                  image: img,
                };
              });
            }}
          />
        </div>
      </div>
      <DialogFooter className="mt-2">
        <Button
          type="submit"
          disabled={!isValid || (!isChanged && !formData.image)}
          isLoading={editRestaurantAd.isPending}
        >
          {t('ads.edit')}
        </Button>
      </DialogFooter>
    </form>
  );
};
