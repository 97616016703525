import { useMemo, useState } from 'react';
import { format, isEqual } from 'date-fns';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SortingState } from '@tanstack/react-table';
import { ShortCompanyLocation } from '@durma-soft/gros-sdk/dist/types/company';
import {
  useAuthUserCompany,
  useGetCompanyLocationsQuery,
  useGetCompanyNumberOfOrdersPerEmployeeReportQuery,
} from '@durma-soft/gros-sdk';

import TableSizeSyncProvider from '@/providers/TableSizeSyncProvider';

import { useDatePickerRangeState } from '@/hooks/use-date-picker-range-state';

import { PrintButton } from '@/components/shared/print-button';
import { Skeleton } from '@/components/shared/shadcn-ui/skeleton';
import { LastUpdatedInfo } from '@/components/shared/last-updated-info';
import { DatePickerWithRange } from '@/components/shared/date-picker-with-range';
import { SelectSingleCompanyLocations } from '@/components/shared/select-single-company-locations';
import { CompanyOrdersByEmployeeModal } from '@/components/company/reports/orders-by-company-employee-modal';
import { NumberOfCustomerCompanyOrders } from '@/components/company/reports/number-of-customer-company-orders';

import print from '@/lib/print/print';
import { repeat } from '@/utils/helpers';

const CompanyCustomerOrders = () => {
  const { t } = useTranslation();
  const { employeeId } = useParams();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [locations, setLocations] = useState<ShortCompanyLocation[]>([]);

  const onUpdateLocations = (newLocations: ShortCompanyLocation[]) => {
    setLocations(newLocations);
  };

  const company = useAuthUserCompany();

  const { from, to, setDateRange } = useDatePickerRangeState();

  const {
    data: companyLocations,
    isSuccess: isSuccessCompanies,
    isLoading: isLoadingCompanies,
  } = useGetCompanyLocationsQuery({
    company_id: company.id,
  });

  const {
    data = [],
    isSuccess: isOrdersPerEmployeeSuccess,
    isLoading: isOrdersPerEmpoyeeLoading,
    refetch,
    isRefetching,
    dataUpdatedAt,
  } = useGetCompanyNumberOfOrdersPerEmployeeReportQuery({
    company_id: company.id,
    start_date: from,
    end_date: to,
  });

  const matchedLocations = useMemo(() => {
    if (!isOrdersPerEmployeeSuccess) return [];
    if (!locations.length) return data;
    const selectedLocationIds = locations.map(({ id }) => id);
    return data?.filter(({ id }) => selectedLocationIds.includes(id));
  }, [data, isOrdersPerEmployeeSuccess, locations]);

  const handleRefetch = async () => {
    await refetch();
  };

  return (
    <>
      <Helmet>
        <title>{t('reports.restaurant-customers.title')}</title>
      </Helmet>
      {Boolean(employeeId) && <CompanyOrdersByEmployeeModal key={employeeId} />}
      <div className="flex items-center justify-between">
        <h1 className="text-lg font-semibold md:text-2xl">
          {t('reports.restaurant-customers.title')}
        </h1>
        {(isLoadingCompanies || isOrdersPerEmpoyeeLoading) && (
          <Skeleton className="h-10 w-52" />
        )}
        {isSuccessCompanies && isOrdersPerEmployeeSuccess && (
          <LastUpdatedInfo
            dataUpdatedAt={dataUpdatedAt}
            isRefetching={isRefetching}
            handleRefetch={handleRefetch}
          />
        )}
      </div>
      <div className="flex flex-col items-start lg:flex-row lg:items-center gap-5 mt-5 mb-5">
        <div>
          <DatePickerWithRange
            date={{ from: new Date(from), to: new Date(to) }}
            setDate={([from, to]) => setDateRange({ from, to })}
          />
        </div>
        <div>
          {isSuccessCompanies && (
            <SelectSingleCompanyLocations
              locations={companyLocations}
              isLoading={isLoadingCompanies || isOrdersPerEmpoyeeLoading}
              selectedLocations={locations}
              onUpdateLocations={onUpdateLocations}
            />
          )}
        </div>
        <div className="flex-1 justify-end flex">
          <PrintButton
            disabled={isLoadingCompanies || isOrdersPerEmpoyeeLoading}
            onClick={() =>
              print({
                content: (
                  <div>
                    <div>
                      <div className="flex-between">
                        <h1>{t('reports.restaurant-customers.title')} </h1>
                        <div className="flex-row">
                          <p>{t('common.data-updated-at')}</p>
                          <span>&nbsp;</span>
                          <p>
                            <span> {format(dataUpdatedAt, 'dd.MM.yyyy')}</span>
                            <span> {t('common.in')} </span>
                            <span> {format(dataUpdatedAt, 'HH:mm:ss')}</span>
                          </p>
                        </div>
                      </div>
                      <div className="flex-between">
                        <p>
                          {format(from, 'dd.MM.yyyy')}
                          {!isEqual(from, to) &&
                            ` - ${format(to, 'dd.MM.yyyy')}`}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col gap-10">
                      {matchedLocations.map(
                        ({ id, name, users, location_type }) => (
                          <div
                            key={id}
                            className="flex flex-col gap-4 print-in-separate-page"
                          >
                            <h3 className="text-xl font-medium">{name}</h3>
                            <NumberOfCustomerCompanyOrders
                              forPrint
                              users={users}
                              sorting={sorting}
                              setSorting={setSorting}
                              locationType={location_type}
                            />
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                ),
              })
            }
          />
        </div>
      </div>
      <div className="flex flex-col">
        {(isOrdersPerEmpoyeeLoading || isLoadingCompanies) && (
          <div className="flex flex-col gap-10">
            {repeat(5).map((index) => (
              <div key={index}>
                <Skeleton className="w-full rounded-md h-60" />
              </div>
            ))}
          </div>
        )}

        {!isLoadingCompanies &&
          !isLoadingCompanies &&
          !isOrdersPerEmpoyeeLoading &&
          data.length === 0 &&
          locations.length === 0 && (
            <p className="text-center">{t('common.choose-location')}</p>
          )}

        {!isLoadingCompanies &&
          !isLoadingCompanies &&
          !isOrdersPerEmpoyeeLoading &&
          data.length === 0 &&
          locations.length !== 0 && (
            <p className="text-center">{t('common.no-results')}</p>
          )}

        {isOrdersPerEmployeeSuccess &&
          !isOrdersPerEmpoyeeLoading &&
          !isLoadingCompanies && (
            <TableSizeSyncProvider>
              <div className="flex flex-col gap-10">
                {matchedLocations.map(
                  ({ id, name, users, location_type }, index) => (
                    <div
                      key={id}
                      className="flex flex-col gap-4 print-in-separate-page"
                    >
                      <h3 className="text-xl font-medium">{name}</h3>
                      <NumberOfCustomerCompanyOrders
                        users={users}
                        sorting={sorting}
                        setSorting={setSorting}
                        locationType={location_type}
                        isTableSyncReference={index === 0}
                      />
                    </div>
                  ),
                )}
              </div>
            </TableSizeSyncProvider>
          )}
      </div>
    </>
  );
};

export default CompanyCustomerOrders;
