import { toast } from 'sonner';
import LinkifyIt from 'linkify-it';
import { useTranslation } from 'react-i18next';
import { FormEvent, useId, useMemo, useRef, useState } from 'react';
import {
  useAuthUserRestaurant,
  useCreateAdMutation,
  ValidationError,
} from '@durma-soft/gros-sdk';

import { Button } from '@/components/shared/shadcn-ui/button';
import { Input } from '@/components/shared/shadcn-ui/input';
import { Label } from '@/components/shared/shadcn-ui/label';
import { DialogFooter } from '@/components/shared/shadcn-ui/dialog';
import AdCropper, { CropperHandle } from '@/components/restaurant/ad/cropper';

import { cn } from '@/utils/helpers';
import { AdFormData } from '@/types/ad';
import { getBlobFromCanvas } from '@/utils/cropper';

interface CreateRestaurantAdFormProps {
  closeModal: () => unknown;
}

const initialFormData = {
  name: '',
  url: '',
  image: null,
};
const linkify = new LinkifyIt();

linkify.normalize = function (match) {
  if (!match.url.startsWith('https://')) {
    match.url = 'https://' + match.url.replace(/^https?:\/\//, '');
  }
};

export const CreateRestaurantAdForm = ({
  closeModal,
}: CreateRestaurantAdFormProps) => {
  const { t } = useTranslation();

  const cropperRef = useRef<CropperHandle>(null);

  const [formData, setFormData] = useState<AdFormData>(initialFormData);

  const uid = useId();

  const restaurant = useAuthUserRestaurant();
  const createRestaurantAd = useCreateAdMutation();

  const isURLValid = useMemo(() => {
    return Boolean(formData.url && linkify.match(formData.url)?.[0].url);
  }, [formData.url]);

  const isValid = useMemo(() => {
    return formData.name.length > 0 && formData.image && isURLValid;
  }, [formData.name, formData.image, isURLValid]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValid) return;
    if (!cropperRef.current) return;

    const canvas = cropperRef.current.getCanvas();
    if (!canvas) return;
    const file: File = (await getBlobFromCanvas(canvas)) as File;

    if (file === null) {
      toast('ads.cropping-fail');
      return;
    }

    const url = linkify.match(formData.url)?.[0].url || '';

    try {
      await createRestaurantAd.mutateAsync({
        restaurant_id: restaurant.id,
        name: formData.name,
        image: file,
        url,
      });
      closeModal();
      setFormData(initialFormData);
      toast.success(t('ads.create-success-msg'));
    } catch (error) {
      if (error instanceof ValidationError) {
        return toast.error(error.message);
      }
      toast.error(t('ads.create-error-msg'));
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col flex-1 gap-6 overflow-hidden"
    >
      <div className="flex flex-col flex-1 gap-4 p-2 overflow-y-auto">
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-name'}>{t('common.name')}</Label>
          <Input
            required
            id={uid + '-name'}
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            placeholder={t('common.name-placeholder')}
          />
        </div>
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-url'}>{t('common.url')}</Label>
          <Input
            required
            id={uid + '-url'}
            value={formData.url}
            onChange={(e) => setFormData({ ...formData, url: e.target.value })}
            placeholder={t('common.url-placeholder')}
            className={cn(
              !isURLValid
                ? 'focus-visible:!ring-red-500'
                : 'focus-visible:!ring-primary',
            )}
          />
        </div>
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-image'}>{t('common.image')}</Label>
          <AdCropper
            ref={cropperRef}
            img={formData.image}
            setImg={(img) => {
              setFormData((prevState) => {
                return {
                  ...prevState,
                  image: img,
                };
              });
            }}
          />
        </div>
      </div>
      <DialogFooter className="mt-2">
        <Button
          type="submit"
          disabled={!isValid}
          isLoading={createRestaurantAd.isPending}
        >
          {t('ads.create')}
        </Button>
      </DialogFooter>
    </form>
  );
};
