/* eslint-disable */
import { Link } from 'react-router-dom';
import { Pencil } from 'lucide-react';
import { ColumnDef } from '@tanstack/react-table';
import { COMPANY_USER_ROLE, useAuthUserCompany } from '@durma-soft/gros-sdk';
import { CompanyTrackLocationWithCompanyLocationsCount } from '@durma-soft/gros-sdk/dist/types/company';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/shared/shadcn-ui/tooltip';

import { TType } from '@/types/general';
import { Badge } from '@/components/shared/shadcn-ui/badge';

export const columns = (
  t: TType,
): ColumnDef<CompanyTrackLocationWithCompanyLocationsCount>[] => [
  {
    accessorKey: 'name',
    enableSorting: false,
    header: t('common.name'),
  },
  {
    accessorKey: 'company_locations_count',
    enableSorting: false,
    header: t('company.track-location.count'),
    cell: (cellData) => {
      return (
        <Badge className="bg-muted border-gray-300 text-gray-700 hover:bg-muted transition-all duration-300">
          {cellData.row.original.company_locations_count}
        </Badge>
      );
    },
  },
  {
    id: 'actions',
    enableHiding: false,
    cell: (cellData) => {
      const company = useAuthUserCompany();
      const hasEditPermission =
        company.role !== COMPANY_USER_ROLE.EMPLOYEE &&
        company.role !== COMPANY_USER_ROLE.TRACK_EMPLOYEE;
      return (
        <TooltipProvider delayDuration={100}>
          <div className="flex items-center justify-center gap-4">
            {/* Edit company track location */}
            {hasEditPermission && (
              <Tooltip>
                <TooltipTrigger>
                  <Link
                    replace
                    to={`/company/track-locations/edit/${cellData.row.original.id}`}
                    className="flex items-center justify-center text-gray-700 "
                  >
                    <Pencil className="w-5 h-5" />
                  </Link>
                  <TooltipContent>
                    {t('company.track-location.edit-details')}
                  </TooltipContent>
                </TooltipTrigger>
              </Tooltip>
            )}
          </div>
        </TooltipProvider>
      );
    },
  },
];
