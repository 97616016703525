import { useState } from 'react';
import { format, isEqual } from 'date-fns';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SortingState } from '@tanstack/react-table';
import {
  useAuthUserRestaurant,
  useGetAllRestaurantCompaniesQuery,
  useGetNumberOfOrdersPerEmployeeReportQuery,
} from '@durma-soft/gros-sdk';
import { ShortRestaurantLocation } from '@durma-soft/gros-sdk/dist/types/restaurants';

import { repeat } from '@/utils/helpers';

import { useDatePickerRangeState } from '@/hooks/use-date-picker-range-state';

import TableSizeSyncProvider from '@/providers/TableSizeSyncProvider';

import { PrintButton } from '@/components/shared/print-button';
import { Skeleton } from '@/components/shared/shadcn-ui/skeleton';
import { LastUpdatedInfo } from '@/components/shared/last-updated-info';
import { DatePickerWithRange } from '@/components/shared/date-picker-with-range';
import { SelectCompanyLocations } from '@/components/shared/select-company-locations';
import { OrdersByEmployeeModal } from '@/components/restaurant/reports/orders-by-employee-modal';
import { NumberOfCustomerOrders } from '@/components/restaurant/reports/number-of-customer-orders';

import print from '@/lib/print/print';

const RestaurantCustomerOrders = () => {
  const { t } = useTranslation();
  const { employeeId } = useParams();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [companyLocations, setCompanyLocations] = useState<
    ShortRestaurantLocation[]
  >([]);

  const onUpdateCompanyLocations = (
    newCompanyLocations: ShortRestaurantLocation[],
  ) => {
    setCompanyLocations(newCompanyLocations);
  };

  const restaurant = useAuthUserRestaurant();

  const { from, to, setDateRange } = useDatePickerRangeState();

  const {
    data: companiesAndGroups,
    isSuccess: isSuccessCompanies,
    isLoading: isLoadingCompanies,
  } = useGetAllRestaurantCompaniesQuery(restaurant.id);

  const {
    data: ordersPerEmployee = [],
    isSuccess: isOrdersPerEmployeeSuccess,
    isLoading: isOrdersPerEmpoyeeLoading,
    refetch,
    isRefetching,
    dataUpdatedAt,
  } = useGetNumberOfOrdersPerEmployeeReportQuery({
    restaurant_id: restaurant.id,
    start_date: from,
    end_date: to,
    company_location_ids: companyLocations.length
      ? companyLocations.map(({ id }) => id)
      : [],
  });

  const handleRefetch = async () => {
    await refetch();
  };

  return (
    <>
      <Helmet>
        <title>{t('reports.restaurant-customers.title')}</title>
      </Helmet>
      {Boolean(employeeId) && <OrdersByEmployeeModal key={employeeId} />}
      <div className="flex items-center justify-between">
        <h1 className="text-lg font-semibold md:text-2xl">
          {t('reports.restaurant-customers.title')}
        </h1>
        {(isLoadingCompanies || isOrdersPerEmpoyeeLoading) && (
          <Skeleton className="h-10 w-52" />
        )}
        {isSuccessCompanies && isOrdersPerEmployeeSuccess && (
          <LastUpdatedInfo
            dataUpdatedAt={dataUpdatedAt}
            isRefetching={isRefetching}
            handleRefetch={handleRefetch}
          />
        )}
      </div>
      <div className="flex flex-col gap-3 my-5 lg:flex-row ">
        <div className="flex-1 flex flex-col gap-3 lg:flex-row ">
          <div className="self-start">
            <DatePickerWithRange
              date={{ from: new Date(from), to: new Date(to) }}
              setDate={([from, to]) => setDateRange({ from, to })}
            />
          </div>
          <div>
            <SelectCompanyLocations
              companies={companiesAndGroups?.companies || []}
              groups={companiesAndGroups?.groups || []}
              isLoading={isLoadingCompanies}
              selectedCompanyLocations={companyLocations}
              onUpdateCompanyLocations={onUpdateCompanyLocations}
            />
          </div>
        </div>
        <div>
          <PrintButton
            disabled={isLoadingCompanies || isOrdersPerEmpoyeeLoading}
            onClick={() =>
              print({
                content: (
                  <div>
                    <div>
                      <div>
                        <div className="flex-between">
                          <h1>{t('reports.restaurant-customers.title')} </h1>
                          <div className="flex-row">
                            <p>{t('common.data-updated-at')}</p>
                            <span>&nbsp;</span>
                            <p>
                              <span>
                                {' '}
                                {format(dataUpdatedAt, 'dd.MM.yyyy')}
                              </span>
                              <span> {t('common.in')} </span>
                              <span> {format(dataUpdatedAt, 'HH:mm:ss')}</span>
                            </p>
                          </div>
                        </div>
                        <p>
                          {format(from, 'dd.MM.yyyy')}
                          {!isEqual(from, to) &&
                            ` - ${format(to, 'dd.MM.yyyy')}`}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col gap-10">
                      {ordersPerEmployee.map(
                        ({ id, name, users, location_type }) => (
                          <div
                            key={id}
                            className="flex flex-col gap-4 print-in-separate-page"
                          >
                            <h3 className="text-xl font-medium">{name}</h3>
                            <NumberOfCustomerOrders
                              forPrint
                              users={users}
                              sorting={sorting}
                              locationType={location_type}
                            />
                          </div>
                        ),
                      )}
                    </div>
                  </div>
                ),
              })
            }
          />
        </div>
      </div>
      <div className="flex flex-col">
        {(isLoadingCompanies || isOrdersPerEmpoyeeLoading) && (
          <div className="flex flex-col gap-10">
            {repeat(5).map((index) => (
              <div key={index}>
                <Skeleton className="w-full rounded-md h-60" />
              </div>
            ))}
          </div>
        )}

        {!isOrdersPerEmpoyeeLoading &&
          ordersPerEmployee.length === 0 &&
          companyLocations.length !== 0 && (
            <p className="text-center">{t('common.no-results')}</p>
          )}

        {companyLocations.length === 0 && (
          <p className="self-center">{t('reports.orders.choose-location')}</p>
        )}

        {isOrdersPerEmployeeSuccess && !isOrdersPerEmpoyeeLoading && (
          <TableSizeSyncProvider>
            <div className="flex flex-col gap-10">
              {ordersPerEmployee.map(
                ({ id, name, location_type, users }, index) => (
                  <div
                    key={id}
                    className="flex flex-col gap-4 print-in-separate-page"
                  >
                    <h3 className="text-xl font-medium">{name}</h3>
                    <NumberOfCustomerOrders
                      users={users}
                      sorting={sorting}
                      setSorting={setSorting}
                      locationType={location_type}
                      isTableSyncReference={index === 0}
                    />
                  </div>
                ),
              )}
            </div>
          </TableSizeSyncProvider>
        )}
      </div>
    </>
  );
};

export default RestaurantCustomerOrders;
