import { Link } from 'react-router-dom';
import { Pencil, UsersRound } from 'lucide-react';
import { ColumnDef } from '@tanstack/react-table';
import { CompanyLocationWithEmployeesCount } from '@durma-soft/gros-sdk/dist/types/company';
import {
  SHIFT,
  COMPANY_USER_ROLE,
  useAuthUserCompany,
} from '@durma-soft/gros-sdk';

import { Badge } from '@/components/shared/shadcn-ui/badge';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/shared/shadcn-ui/tooltip';

import { TType } from '@/types/general';

export const columns = (
  t: TType,
): ColumnDef<CompanyLocationWithEmployeesCount>[] => [
  {
    accessorKey: 'name',
    header: t('common.name'),
  },
  {
    accessorKey: 'address',
    enableSorting: false,
    header: t('common.address'),
    cell: (cellData) => {
      return (
        <ul>
          <li>{cellData.row.original.street}</li>
          <li>{cellData.row.original.city}</li>
          <li>{cellData.row.original.zip}</li>
          <li>{cellData.row.original.state}</li>
          <li>{cellData.row.original.country}</li>
        </ul>
      );
    },
  },
  {
    accessorKey: 'shifts',
    enableSorting: false,
    header: t('common.shifts'),
    cell: (cellData) => {
      const { shift_1, shift_2, shift_3 } = cellData.row.original;
      return (
        <div className="flex gap-2">
          {shift_1 && <Badge className="justify-center">{SHIFT.FIRST}</Badge>}
          {shift_2 && <Badge className="justify-center">{SHIFT.SECOND}</Badge>}
          {shift_3 && <Badge className="justify-center">{SHIFT.THIRD}</Badge>}
        </div>
      );
    },
  },
  {
    accessorKey: 'location_type',
    enableSorting: false,
    header: t('common.location-type'),
    cell: (cellData) => (
      <>{t(`location-type.${cellData.row.original.location_type}`)}</>
    ),
  },
  {
    accessorKey: 'employees_count',
    enableSorting: false,
    header: t('employee.count'),
    cell: (cellData) => {
      return (
        <div className="w-full">
          <Link to={`/company/locations/${cellData.row.original.id}/employees`}>
            <Badge className="bg-muted border-gray-300 text-gray-700 hover:bg-gray-200 transition-all duration-300">
              {cellData.row.original.employees_count}
            </Badge>
          </Link>
        </div>
      );
    },
  },
  {
    id: 'actions',
    enableHiding: false,
    cell: (cellData) => {
      //eslint-disable-next-line
      const company = useAuthUserCompany();

      const hasEditPermission =
        company.role === COMPANY_USER_ROLE.MANAGER ||
        company.role === COMPANY_USER_ROLE.OWNER;

      return (
        <TooltipProvider delayDuration={100}>
          <div className="flex items-center justify-center gap-4">
            {/* Location employees */}
            <Tooltip>
              <TooltipTrigger>
                <Link
                  to={`/company/locations/${cellData.row.original.id}/employees`}
                  className="flex items-center justify-center text-gray-700 "
                >
                  <UsersRound className="w-5 h-5" />
                </Link>
              </TooltipTrigger>
              <TooltipContent>
                {t('company-locations.employees')}
              </TooltipContent>
            </Tooltip>
            {/* Edit location */}
            {hasEditPermission && (
              <Tooltip>
                <TooltipTrigger>
                  <Link
                    replace
                    to={`/company/locations/edit/${cellData.row.original.id}`}
                    className="flex items-center justify-center text-gray-700 "
                  >
                    <Pencil className="w-5 h-5" />
                  </Link>
                  <TooltipContent>
                    {t('company-locations.edit-details')}
                  </TooltipContent>
                </TooltipTrigger>
              </Tooltip>
            )}
          </div>
        </TooltipProvider>
      );
    },
  },
];
