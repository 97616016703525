import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import { FormEvent, useMemo, useState } from 'react';
import {
  ValidationError,
  useAuthUserRestaurant,
  useCreateFoodMutation,
} from '@durma-soft/gros-sdk';

import { Button } from '@/components/shared/shadcn-ui/button';
import { DialogFooter } from '@/components/shared/shadcn-ui/dialog';
import { FoodDetails } from '@/components/restaurant/foods/food-details';
import { IngredientsTab } from '@/components/restaurant/foods/ingredients-tab';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/shared/shadcn-ui/tabs';

import { RestaurantFoodFormData } from '@/types/foods';

interface CreateRestaurantFoodFormProps {
  closeModal: () => unknown;
}

const initialFormData: RestaurantFoodFormData = {
  name: '',
  new: false,
  fasting: false,
  halal: false,
  vegan: false,
  vegetarian: false,
  picture: null,
  ingredients: [],
};

export const CreateRestaurantFoodForm = ({
  closeModal,
}: CreateRestaurantFoodFormProps) => {
  const { t } = useTranslation();
  const [formData, setFormData] =
    useState<RestaurantFoodFormData>(initialFormData);

  const restaurant = useAuthUserRestaurant();
  const createRestaurantFood = useCreateFoodMutation();

  const isValid = useMemo(() => {
    return Boolean(
      formData.name.trim().length > 0 &&
        formData.ingredients.length !== 0 &&
        formData.ingredients.every((ingredient) => {
          return (
            Boolean(ingredient.quantity) &&
            Number.isFinite(+ingredient.quantity) &&
            ingredient.quantity > 0
          );
        }),
    );
  }, [formData]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValid) return;
    try {
      await createRestaurantFood.mutateAsync({
        restaurant_id: restaurant.id,
        new: formData.new,
        name: formData.name,
        fasting: formData.fasting,
        halal: formData.halal,
        vegan: formData.vegan,
        vegetarian: formData.vegetarian,
        picture:
          typeof formData.picture === 'string'
            ? undefined
            : (formData.picture as File),
        ingredients: formData.ingredients.map(
          ({ id, quantity }: { id: number; quantity: number }) => ({
            ingredient_id: id,
            quantity,
          }),
        ),
      });
      closeModal();
      setFormData(initialFormData);
      toast.success(t('food.create-success-msg'));
    } catch (error) {
      if (error instanceof ValidationError) {
        toast.error(error.message);
      }
      toast.error(t('food.create-error-msg'));
    }
  };

  return (
    <Tabs
      defaultValue="details"
      className="flex flex-col flex-1 gap-6 overflow-hidden"
    >
      <form
        onSubmit={handleSubmit}
        className="flex flex-col gap-4 flex-1 overflow-hidden"
      >
        <div className="flex-1 overflow-y-auto pr-3">
          <TabsList className="w-full">
            <TabsTrigger value="details" className="flex-1 w-full">
              {t('common.details')}
            </TabsTrigger>
            <TabsTrigger value="ingredients" className="flex-1 w-full">
              {t('food.ingredients')}
            </TabsTrigger>
          </TabsList>
          <TabsContent value="details">
            <FoodDetails formData={formData} setFormData={setFormData} />
          </TabsContent>
          <TabsContent value="ingredients" className="w-full">
            <IngredientsTab
              ingredients={formData.ingredients}
              setFormData={setFormData}
            />
          </TabsContent>
        </div>
        <DialogFooter className="self-end">
          <Button
            type="submit"
            disabled={!isValid}
            isLoading={createRestaurantFood.isPending}
          >
            {t('food.create')}
          </Button>
        </DialogFooter>
      </form>
    </Tabs>
  );
};
