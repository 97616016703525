import { useTranslation } from 'react-i18next';
import {
  useAuthUserRestaurant,
  useGetSingleAdQuery,
} from '@durma-soft/gros-sdk';
import { useMatch, useNavigate, useParams } from 'react-router-dom';

import { Alert } from '@/components/shared/alert';
import { Modal } from '@/components/shared/modal';
import { ModalFormSkeleton } from '@/components/shared/modal-form-skeleton';
import { EditRestaurantAdForm } from '@/components/restaurant/ad/edit-restaurant-ad-form';

export const EditRestaurantAdModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { adId } = useParams();

  if (!adId) {
    throw new Error('Ad ID is required for editing ad');
  }

  const restaurant = useAuthUserRestaurant();

  const {
    data: ad,
    isSuccess,
    isError,
    isLoading,
  } = useGetSingleAdQuery({
    id: Number(adId),
    restaurant_id: restaurant.id,
  });

  const closeModal = () => navigate('/restaurant/ads', { replace: true });

  const isModalOpen = Boolean(useMatch({ path: '/restaurant/ads/edit/:adId' }));

  return (
    <Modal
      key={adId}
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      title={t('ads.edit')}
      description={t('ads.edit-details')}
    >
      {isLoading && <ModalFormSkeleton />}
      {isError && (
        <Alert
          variant="destructive"
          title={t('common.error')}
          description={t('common.try-later')}
        />
      )}
      {isSuccess && <EditRestaurantAdForm ad={ad} onClose={closeModal} />}
    </Modal>
  );
};
