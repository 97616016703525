import { useState } from 'react';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import {
  useAuthUserRestaurant,
  useGetAllRestaurantCompaniesQuery,
} from '@durma-soft/gros-sdk';

import { useDatePickerState } from '@/hooks/use-date-picker-state';

import { DatePicker } from '@/components/shared/date-picker';
import { Skeleton } from '@/components/shared/shadcn-ui/skeleton';
import { SelectSingleLocation } from '@/components/shared/select-single-location';
import { ManualOrders as ManualOrdersWrapper } from '@/components/restaurant/manual-orders/manual-orders';
import { repeat } from '@/utils/helpers';

const ManualOrders = () => {
  const { t } = useTranslation();
  const restaurant = useAuthUserRestaurant();

  const { date, setDate, datePickerOpen, setDatePickerOpen } =
    useDatePickerState({ storeInUrl: false });

  const [locationId, setLocationId] = useState<number | null>(null);

  const { data: companiesAndGroups, isLoading: isLoading } =
    useGetAllRestaurantCompaniesQuery(restaurant.id);

  return (
    <>
      <Helmet>
        <title>{t('manual-orders.title')}</title>
      </Helmet>
      <div>
        <div className="flex items-center">
          <h1 className="text-lg font-semibold md:text-2xl">
            {t('manual-orders.title')}
          </h1>
        </div>
        <div className="mt-10 flex gap-3">
          <div>
            <DatePicker
              open={datePickerOpen}
              onOpenChange={setDatePickerOpen}
              date={new Date(date)}
              setDate={(newDate) => {
                if (!newDate) return;
                setDate(format(newDate, 'yyyy-MM-dd'));
                setDatePickerOpen(false);
              }}
            />
          </div>
          <div>
            <SelectSingleLocation
              companies={companiesAndGroups?.companies || []}
              isLoading={isLoading}
              locationId={locationId}
              setLocationId={setLocationId}
            />
          </div>
        </div>
        <div className="mt-5 flex flex-col gap-3">
          {isLoading && (
            <div className="flex flex-col gap-10">
              {repeat(3).map((index) => (
                <div key={index}>
                  <Skeleton className="w-full rounded-md h-60" />
                </div>
              ))}
            </div>
          )}

          {!isLoading && !locationId && (
            <p className="self-center mt-10">
              {t('manual-orders.choose-location')}
            </p>
          )}

          {!!date && !!locationId && (
            <ManualOrdersWrapper
              key={`${date}-${locationId}`}
              date={date}
              companyLocationId={locationId}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ManualOrders;
