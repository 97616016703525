import { format } from 'date-fns';
import { ColumnDef } from '@tanstack/react-table';
import { OrdersByEmployeeReportOrder } from '@durma-soft/gros-sdk/dist/types/reports';

import { TType } from '@/types/general';

export const columns = (t: TType): ColumnDef<OrdersByEmployeeReportOrder>[] => [
  {
    accessorKey: 'id',
    enableSorting: false,
    header: 'ID',
    cell: (cellData) => {
      return <>{cellData.row.original.employee.id}</>;
    },
  },
  {
    accessorKey: 'employee',
    header: t('employee.title'),
    cell: (cellData) => {
      const { first_name, last_name } = cellData.row.original.employee;
      return <>{`${first_name}  ${last_name}`}</>;
    },
  },
  {
    accessorKey: 'food',
    header: t('reports.orders.food'),
    cell: (cellData) => {
      const { name: orderName } = cellData.row.original.food;
      return <>{orderName}</>;
    },
  },
  {
    accessorKey: 'date',
    header: t('common.date'),
    cell: (cellData) => {
      const { date } = cellData.row.original;
      return <>{format(date, 'dd.MM.yyyy')}</>;
    },
  },
];
