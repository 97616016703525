import { Trash2, Upload } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import {
  ChangeEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  CropperProps,
  CropperRef,
  Cropper as DefaultCropper,
  ImageRestriction,
  mergeRefs,
  ResizeAlgorithm,
} from 'react-advanced-cropper';
import {
  getZoomFactor as getZoomFactorPlugin,
  getAbsoluteZoom as getAbsoluteZoomPlugin,
} from 'advanced-cropper/extensions/absolute-zoom';
import {
  defaultSize,
  transformImage,
  resizeCoordinates,
  stencilConstraints,
} from 'advanced-cropper/showcase/mobile';

import { Slider } from '@/components/shared/shadcn-ui/slider';
import CropperWrapper from '@/components/restaurant/ad//cropper-wrapper';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/shared/shadcn-ui/tooltip';

import { getStencilConfig, hybridStencilAutoZoom } from '@/utils/cropper';

export type CropperHandle = {
  refresh: () => void;
  getCanvas: () => HTMLCanvasElement | null;
};

type DesktopCropperProps = CropperProps & {
  img: string | null;
  setImg: (img: string | null) => void;
  actionMessage?: string;
};

const AdCropper = forwardRef<CropperHandle, DesktopCropperProps>(
  (props: DesktopCropperProps, ref) => {
    const { t } = useTranslation();
    const cropperRef = useRef<CropperRef>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => ({
      refresh: () => {
        cropperRef.current?.refresh();
      },
      getCanvas: () => {
        return cropperRef.current?.getCanvas() || null;
      },
    }));

    const {
      img,
      setImg,
      actionMessage = t('common.add-image'),
      ...cropperProps
    } = props;

    const [zoom, setZoom] = useState(0);
    const [initialZoom] = useState(0);

    const onZoom = (value: number, transitions?: boolean) => {
      if (!cropperRef.current) return;
      const state = cropperRef.current.getState();
      const settings = cropperRef.current.getSettings();
      cropperRef.current.zoomImage(
        getZoomFactorPlugin(state, settings, value),
        {
          transitions: !!transitions,
        },
      );
    };

    const onUpload = () => {
      if (inputRef.current) {
        inputRef.current.click();
      }
    };

    const onLoadImage = (event: ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;
      if (files && files[0]) {
        setImg(URL.createObjectURL(files[0]));
      }
      event.target.value = '';
    };

    useEffect(() => {
      return () => {
        if (img) {
          URL.revokeObjectURL(img as string);
        }
      };
    }, [img]);

    return (
      <>
        {!img && (
          <div
            className="relative w-full h-16 border border-input bg-black/5 rounded-md transition-all duration-300 hover:bg-black/10 hover:cursor-pointer"
            role="button"
            onClick={onUpload}
          >
            <div className="h-full flex flex-col gap-1 items-center justify-center pointer-events-none text-black/75">
              <Upload className="w-4 h-4" />
              <span className="text-sm">{actionMessage}</span>
              <input
                className="hidden"
                ref={inputRef}
                type="file"
                accept="image/*"
                onChange={onLoadImage}
              />
            </div>
          </div>
        )}
        {img && (
          <div className="flex flex-col gap-6 relative mx-6">
            <DefaultCropper
              className="!bg-white !rounded-md"
              {...cropperProps}
              minHeight={16}
              minWidth={16}
              src={img}
              ref={mergeRefs([ref, cropperRef])}
              stencilProps={{
                movable: false,
                ...getStencilConfig(320 / 60),
              }}
              onReady={(cropper) => {
                cropper.refresh();

                const state = cropper.getState();
                const settings = cropper.getSettings();

                const zoom = getAbsoluteZoomPlugin(state, settings, true);

                setZoom(zoom);
              }}
              onUpdate={(cropper) => {
                const state = cropper.getState();
                const settings = cropper.getSettings();

                setZoom(getAbsoluteZoomPlugin(state, settings));
              }}
              wrapperComponent={CropperWrapper}
              defaultSize={defaultSize}
              stencilConstraints={stencilConstraints}
              transformImageAlgorithm={transformImage}
              imageRestriction={ImageRestriction.stencil}
              postProcess={[hybridStencilAutoZoom]}
              resizeCoordinatesAlgorithm={resizeCoordinates as ResizeAlgorithm}
            />
            <Slider
              value={[zoom]}
              min={initialZoom}
              max={0.9}
              step={0.01}
              onValueChange={(value) => {
                const [zoom] = value;
                onZoom(zoom, false);
              }}
            />

            <TooltipProvider>
              <Tooltip delayDuration={200}>
                <TooltipTrigger
                  role="button"
                  className="p-1.5 bg-white/75 rounded-full absolute top-1.5 right-1.5 hover:bg-white/90 border border-input transition-all duration-200 cursor-pointer"
                  onClick={() => setImg('')}
                >
                  <Trash2 className="w-4 h-4" />
                </TooltipTrigger>
                <TooltipContent>{t('common.delete')}</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        )}
      </>
    );
  },
);

export default AdCropper;
