import { ArrowDownUp, Pencil } from 'lucide-react';
import { ColumnDef } from '@tanstack/react-table';
import {
  convertUnits,
  RESTAURANT_USER_ROLE,
  Unit,
  useAuthUserRestaurant,
} from '@durma-soft/gros-sdk';
import { RestaurantIngredientWithInventoryChanges } from '@durma-soft/gros-sdk/dist/types/restaurants';

import { Link } from '@/components/shared/link';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/shared/shadcn-ui/tooltip';

import { TType } from '@/types/general';

export const columns = (
  t: TType,
): ColumnDef<RestaurantIngredientWithInventoryChanges>[] => [
  {
    accessorKey: 'name',
    header: t('common.name'),
  },
  {
    accessorKey: 'start_quantity',
    enableSorting: false,
    header: t('ingredient.start-quantity'),
    cell: (cellData) => {
      const { start_quantity, unit, preferred_display_unit } =
        cellData.row.original;

      return (
        <div className="flex gap-2">
          <span>
            {convertUnits({
              from: unit as Unit,
              to: (preferred_display_unit as Unit) || (unit as Unit),
              quantity: start_quantity,
            }) +
              ' ' +
              t(
                `common.measure-unit-short.${preferred_display_unit}`,
              ).toLowerCase()}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: 'addition_quantity',
    enableSorting: false,
    header: t('ingredient.addition-quantity'),
    cell: (cellData) => {
      const { addition_quantity, unit, preferred_display_unit } =
        cellData.row.original;

      return (
        <div className="flex gap-2">
          <span>
            {convertUnits({
              from: unit as Unit,
              to: (preferred_display_unit as Unit) || (unit as Unit),
              quantity: addition_quantity,
            }) +
              ' ' +
              t(
                `common.measure-unit-short.${preferred_display_unit}`,
              ).toLowerCase()}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: 'subtraction_quantity',
    enableSorting: false,
    header: t('ingredient.subtraction-quantity'),
    cell: (cellData) => {
      const { subtraction_quantity, unit, preferred_display_unit } =
        cellData.row.original;

      return (
        <div className="flex gap-2">
          <span>
            {convertUnits({
              from: unit as Unit,
              to: (preferred_display_unit as Unit) || (unit as Unit),
              quantity: subtraction_quantity,
            }) +
              ' ' +
              t(
                `common.measure-unit-short.${preferred_display_unit}`,
              ).toLowerCase()}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: 'end_quantity',
    enableSorting: false,
    header: t('ingredient.current-quantity'),
    cell: (cellData) => {
      const { end_quantity, unit, preferred_display_unit } =
        cellData.row.original;

      return (
        <div className="flex gap-2">
          <span className={`${end_quantity < 0 && 'text-red-500'}`}>
            {convertUnits({
              from: unit as Unit,
              to: (preferred_display_unit as Unit) || (unit as Unit),
              quantity: end_quantity,
            }) +
              ' ' +
              t(
                `common.measure-unit-short.${preferred_display_unit}`,
              ).toLowerCase()}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: 'edit',
    enableSorting: false,
    header: '',
    cell: (cellData) => {
      // eslint-disable-next-line
      const restaurant = useAuthUserRestaurant();

      if (restaurant.role !== RESTAURANT_USER_ROLE.OWNER) return null;
      return (
        <TooltipProvider delayDuration={100}>
          <div className="flex items-center justify-center gap-5">
            {/* Edit quantity */}
            <Tooltip>
              <TooltipTrigger asChild>
                <Link
                  replace
                  to={`/restaurant/ingredients/edit-quantity/${cellData.row.original.id}`}
                  className="flex items-center justify-center text-gray-700"
                >
                  <ArrowDownUp className="w-5 h-5" />
                </Link>
              </TooltipTrigger>
              <TooltipContent>{t('ingredient.edit-quantity')}</TooltipContent>
            </Tooltip>
            {/* Edit ingredient */}
            <Tooltip>
              <TooltipTrigger asChild>
                <Link
                  replace
                  to={`/restaurant/ingredients/edit/${cellData.row.original.id}`}
                  className="flex items-center justify-center text-gray-700"
                >
                  <Pencil className="w-5 h-5" />
                </Link>
              </TooltipTrigger>
              <TooltipContent>{t('ingredient.edit-details')}</TooltipContent>
            </Tooltip>
          </div>
        </TooltipProvider>
      );
    },
  },
];
