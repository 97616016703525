import { Dispatch, SetStateAction, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SortingState } from '@tanstack/react-table';
import { OrdersByFoodReportRowFood } from '@durma-soft/gros-sdk/dist/types/reports';

import { DataTable, DataTableHTML } from '@/components/shared/data-table';

import { columns } from '@/config/restaurant/reports/orders-by-food';

interface OrdersByFoodProps {
  forPrint?: boolean;
  isTableSyncReference?: boolean;
  sorting?: SortingState;
  setSorting?: Dispatch<SetStateAction<SortingState>>;
  foods: OrdersByFoodReportRowFood[];
}

export const OrdersByFood = ({
  foods,
  forPrint = false,
  sorting,
  setSorting,
  isTableSyncReference,
}: OrdersByFoodProps) => {
  const { t } = useTranslation();

  const memoizedColumns = useMemo(() => columns(t), [t]);

  return (
    <>
      {!forPrint ? (
        <DataTable
          data={foods}
          columns={memoizedColumns}
          isSyncReference={isTableSyncReference}
          sorting={sorting}
          setSorting={setSorting}
        />
      ) : (
        <DataTableHTML
          data={foods}
          columns={memoizedColumns}
          sorting={sorting}
          setSorting={setSorting}
        />
      )}
    </>
  );
};
