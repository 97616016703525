import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import Layout from '@/layout/layout';

import AppModeProvider from '@/providers/AppModeProvider';

import { ProtectedRoute } from '@//middleware/protected-route';
import { NonProtectedRoute } from '@/middleware/non-protected-route';
import { UserCanAccessTrackMiddleware } from '@/middleware/user-can-access-track-middleware';
import { UserIsCompanyAdministratorMiddleware } from '@/middleware/user-is-company-administrator-middleware';
import { UserHasCompanyOrRestaurantMiddleware } from '@/middleware/user-has-company-or-restaurant-middleware';
import { UserCanAccessRestaurantTrackMiddleware } from '@/middleware/user-can-access-restaurant-track-middleware';
import { UserIsNotRestaurantTrackEmployeeMiddleware } from '@/middleware/user-is-not-restaurant-track-employee-middleware';

import Auth from '@/pages/auth/index';
import Login from '@/pages/auth/login';
import ResetPassword from '@/pages/auth/reset-password';
import ForgotPassword from '@/pages/auth/forgot-password';

import Restaurant from '@/pages/restaurant';
import RestaurantTrack from '@/pages/restaurant/track';
import RestaurantMenus from '@/pages/restaurant/menus';
import RestaurantFoods from '@/pages/restaurant/foods';
import RestaurantPartners from '@/pages/restaurant/partners';
import RestaurantSettings from '@/pages/restaurant/settings';
import RestaurantCompanies from '@/pages/restaurant/companies';
import RestaurantEmployees from '@/pages/restaurant/employees';
import RestaurantMenuTypes from '@/pages/restaurant/menu-types';
import RestaurantIngredients from '@/pages/restaurant/ingredients';
import RestaurantManualOrders from '@/pages/restaurant/manual-orders';
import RestaurantCompanyGroups from '@/pages/restaurant/company-groups';
import RestaurantTrackLocations from '@/pages/restaurant/track-locations';
import RestaurantCompanyEmployees from '@/pages/restaurant/company-employees';
import RestaurantCompanyLocations from '@/pages/restaurant/company-locations';
import RestaurantCompanyTrackLocations from '@/pages/restaurant/company-track-locations';
import RestaurantCompanyLocationEmployees from '@/pages/restaurant/company-location-employees';

import Reports from '@/pages/restaurant/reports';
import FoodsReport from '@/pages/restaurant/reports/foods';
import OrdersReport from '@/pages/restaurant/reports/orders';
import PackagesReport from '@/pages/restaurant/reports/packages';
import InvoicingReport from '@/pages/restaurant/reports/invoicing';
import RestaurantReport from '@/pages/restaurant/reports/restaurants';
import IngredientsReport from '@/pages/restaurant/reports/ingredients';
import OrdersByFoodReport from '@/pages/restaurant/reports/orders-by-food';
import SubstituteMealsReport from '@/pages/restaurant/reports/substitute-meals';
import RestaurantCustomerOrders from '@/pages/restaurant/reports/restaurant-customer-orders';

import Company from '@/pages/company';
import CompanyTrack from '@/pages/company/track';
import CompanyEmployees from '@/pages/company/employees';
import CompanyLocations from '@/pages/company/locations';
import CompanyLocationEmployees from '@/pages/company/location-employees';

import CompanyReports from '@/pages/company/reports';
import CompanyFoodsReport from '@/pages/company/reports/foods';
import CompanyTrackLocations from '@/pages/company/track-locations';
import CompanyCustomerOrders from '@/pages/company/reports/customer-orders';
import CompanyOrdersByFoodReport from '@/pages/company/reports/orders-by-food';
import OrdersByEmployeeReport from '@/pages/company/reports/orders-by-employee';

import Index from '@/pages/index';
import NotFound from '@/pages/404';
import NotAllowed from '@/pages/not-allowed';

import { Alert } from '@/components/shared/alert';
import { Spinner } from '@/components/shared/spinner';
import { Button } from '@/components/shared/shadcn-ui/button';
import RestaurantAds from './pages/restaurant/ads';

function App() {
  const { t } = useTranslation();
  return (
    <Routes>
      {/* Non protected route */}
      <Route
        path="/auth"
        element={
          <NonProtectedRoute>
            <Auth />
          </NonProtectedRoute>
        }
      >
        <Route index element={<Login />} />
        <Route path="login" element={<Login />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
      </Route>
      {/* Protected route */}
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <UserHasCompanyOrRestaurantMiddleware>
              <AppModeProvider>
                <Layout />
              </AppModeProvider>
            </UserHasCompanyOrRestaurantMiddleware>
          </ProtectedRoute>
        }
      >
        {/* Restaurant */}
        <Route path="restaurant" element={<Restaurant />}>
          {/* Track */}
          <Route
            path="track"
            element={<UserCanAccessRestaurantTrackMiddleware />}
          >
            <Route
              path=""
              element={
                <ErrorBoundary
                  fallbackRender={({ resetErrorBoundary }) => {
                    return (
                      <div className="flex flex-col gap-3">
                        <Alert
                          variant="destructive"
                          title={t('common.error')}
                          description={t('common.error-description')}
                        />
                        <Button onClick={resetErrorBoundary}>
                          {t('common.try-again')}
                        </Button>
                      </div>
                    );
                  }}
                >
                  <Suspense
                    fallback={
                      <div className="flex-1 flex items-center justify-center">
                        <Spinner />
                      </div>
                    }
                  >
                    <RestaurantTrack />
                  </Suspense>
                </ErrorBoundary>
              }
            />
          </Route>
          <Route
            path=""
            element={<UserIsNotRestaurantTrackEmployeeMiddleware />}
          >
            <Route path="menus" element={<RestaurantMenus />}>
              <Route path="create" element={<RestaurantMenus />} />
              <Route path="edit/:menuId" element={<RestaurantMenus />} />
              <Route path="copy" element={<RestaurantMenus />} />
            </Route>

            <Route path="ingredients" element={<RestaurantIngredients />}>
              <Route path="create" element={<RestaurantIngredients />} />
              <Route
                path="edit/:ingredientId"
                element={<RestaurantIngredients />}
              />
              <Route
                path="edit-quantity/:ingredientId"
                element={<RestaurantIngredients />}
              />
            </Route>

            <Route path="foods" element={<RestaurantFoods />}>
              <Route path="create" element={<RestaurantFoods />} />
              <Route path="edit/:foodId" element={<RestaurantFoods />} />
              <Route path="subtract/:foodId" element={<RestaurantFoods />} />
            </Route>

            <Route path="menu-types" element={<RestaurantMenuTypes />}>
              <Route path="create" element={<RestaurantMenuTypes />} />
              <Route
                path="edit/:menuTypeId"
                element={<RestaurantMenuTypes />}
              />
            </Route>

            <Route path="employees" element={<RestaurantEmployees />}>
              <Route path="create" element={<RestaurantEmployees />} />
              <Route
                path="edit/:employeeId"
                element={<RestaurantEmployees />}
              />
            </Route>

            <Route path="manual-orders" element={<RestaurantManualOrders />} />

            <Route path="partners" element={<RestaurantPartners />}>
              <Route path="create" element={<RestaurantPartners />} />
              <Route path="edit/:partnerId" element={<RestaurantPartners />} />
            </Route>

            <Route
              path="track-locations"
              element={<RestaurantTrackLocations />}
            >
              <Route path="create" element={<RestaurantTrackLocations />} />
              <Route
                path="edit/:trackLocationId"
                element={<RestaurantTrackLocations />}
              />
            </Route>

            <Route path="ads" element={<RestaurantAds />}>
              <Route path="create" element={<RestaurantAds />} />
              <Route path="edit/:adId" element={<RestaurantAds />} />
            </Route>

            <Route path="settings" element={<RestaurantSettings />} />

            {/* Reports */}
            <Route path="reports" element={<Reports />}>
              <Route path="ingredients" element={<IngredientsReport />} />
              <Route path="foods" element={<FoodsReport />} />
              <Route path="orders" element={<OrdersReport />} />
              <Route path="orders-by-food" element={<OrdersByFoodReport />} />
              <Route path="packages" element={<PackagesReport />} />
              <Route
                path="restaurant-customer-orders"
                element={<RestaurantCustomerOrders />}
              >
                <Route
                  path="single-employee/:employeeId"
                  element={<RestaurantCustomerOrders />}
                />
              </Route>

              <Route
                path="substitute-meals"
                element={<SubstituteMealsReport />}
              />
              <Route path="restaurants" element={<RestaurantReport />} />
              <Route path="invoicing" element={<InvoicingReport />} />
            </Route>

            {/* Restaurant company */}
            <Route path="companies" element={<RestaurantCompanies />}>
              <Route path="create" element={<RestaurantCompanies />} />
              <Route path="edit/:companyId" element={<RestaurantCompanies />} />
            </Route>

            {/* Restaurant companies groups */}
            <Route
              path="companies/groups"
              element={<RestaurantCompanyGroups />}
            >
              <Route path="create" element={<RestaurantCompanyGroups />} />
              <Route
                path="edit/:groupId"
                element={<RestaurantCompanyGroups />}
              />
            </Route>

            {/* Restaurant company employees */}
            <Route
              path="companies/:companyId/employees"
              element={<RestaurantCompanyEmployees />}
            >
              <Route path="create" element={<RestaurantCompanyEmployees />} />

              <Route
                path="edit/:employeeId"
                element={<RestaurantCompanyEmployees />}
              />
              <Route path="import" element={<RestaurantCompanies />} />
            </Route>

            {/* Restaurant company locations */}
            <Route
              path="companies/:companyId/locations"
              element={<RestaurantCompanyLocations />}
            >
              <Route path="create" element={<RestaurantCompanyLocations />} />
              <Route
                path="edit/:locationId"
                element={<RestaurantCompanyLocations />}
              />
            </Route>

            {/* Restaurant company track locations */}
            <Route
              path="companies/:companyId/track-locations"
              element={<RestaurantCompanyTrackLocations />}
            >
              <Route
                path="create"
                element={<RestaurantCompanyTrackLocations />}
              />
              <Route
                path="edit/:trackLocationId"
                element={<RestaurantCompanyTrackLocations />}
              />
            </Route>

            {/* Restaurant company location employees */}
            <Route
              path="companies/:companyId/locations/:locationId/employees"
              element={<RestaurantCompanyLocationEmployees />}
            >
              <Route
                path="create"
                element={<RestaurantCompanyLocationEmployees />}
              />
              <Route
                path="edit/:employeeId"
                element={<RestaurantCompanyLocationEmployees />}
              />
              <Route
                path="import"
                element={<RestaurantCompanyLocationEmployees />}
              />
            </Route>
          </Route>
        </Route>

        {/* Company */}
        <Route path="company" element={<Company />}>
          <Route path="track" element={<UserCanAccessTrackMiddleware />}>
            <Route
              path=""
              element={
                <ErrorBoundary
                  fallbackRender={({ resetErrorBoundary }) => {
                    return (
                      <div className="flex flex-col gap-3">
                        <Alert
                          variant="destructive"
                          title={t('common.error')}
                          description={t('common.error-description')}
                        />
                        <Button onClick={resetErrorBoundary}>
                          {t('common.try-again')}
                        </Button>
                      </div>
                    );
                  }}
                >
                  <Suspense
                    fallback={
                      <div className="flex-1 flex items-center justify-center">
                        <Spinner />
                      </div>
                    }
                  >
                    <CompanyTrack />
                  </Suspense>
                </ErrorBoundary>
              }
            />
          </Route>
          <Route path="" element={<UserIsCompanyAdministratorMiddleware />}>
            {/* Locations */}
            <Route path="locations" element={<CompanyLocations />}>
              <Route path="create" element={<CompanyLocations />} />
              <Route path="edit/:locationId" element={<CompanyLocations />} />
            </Route>

            {/* Employees */}
            <Route path="employees" element={<CompanyEmployees />}>
              <Route path="create" element={<CompanyEmployees />} />
              <Route path="edit/:employeeId" element={<CompanyEmployees />} />
            </Route>

            {/* Track locations */}
            <Route path="track-locations" element={<CompanyTrackLocations />}>
              <Route path="create" element={<CompanyTrackLocations />} />
              <Route
                path="edit/:trackLocationId"
                element={<CompanyTrackLocations />}
              />
            </Route>

            {/* Location employees */}
            <Route
              path="locations/:locationId/employees"
              element={<CompanyLocationEmployees />}
            >
              <Route path="create" element={<CompanyLocationEmployees />} />
              <Route
                path="edit/:employeeId"
                element={<CompanyLocationEmployees />}
              />
            </Route>

            {/* Company reports */}
            <Route path="reports" element={<CompanyReports />}>
              <Route path="foods" element={<CompanyFoodsReport />} />
              <Route path="orders" element={<OrdersByEmployeeReport />} />
              <Route path="customer-orders" element={<CompanyCustomerOrders />}>
                <Route
                  path="single-employee/:employeeId"
                  element={<CompanyCustomerOrders />}
                />
              </Route>
              <Route
                path="orders-by-food"
                element={<CompanyOrdersByFoodReport />}
              />
            </Route>
          </Route>
        </Route>

        {/* Not allowed */}
        <Route path="/not-allowed" element={<NotAllowed />} />
        {/* Index */}
        <Route path="/" element={<Index />} />
        {/* Not found */}
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
