import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import { SortingState } from '@tanstack/react-table';
import { FormEvent, useId, useMemo, useState } from 'react';
import {
  useAuthUserRestaurant,
  useEditCompanyLocationGroupMutation,
  ValidationError,
} from '@durma-soft/gros-sdk';
import {
  LocationGroupWithCompanyLocations,
  ShortRestaurantCompanyWithLocations,
  ShortRestaurantLocation,
} from '@durma-soft/gros-sdk/dist/types/restaurants';

import { useChangedObject } from '@/hooks/use-changed-object';

import { DataTable } from '@/components/shared/data-table';
import { Input } from '@/components/shared/shadcn-ui/input';
import { Label } from '@/components/shared/shadcn-ui/label';
import { Button } from '@/components/shared/shadcn-ui/button';
import { DialogFooter } from '@/components/shared/shadcn-ui/dialog';
import { SelectCompanyLocations } from '@/components/shared/select-company-locations';

import { locationsColumns } from '@/config/restaurant/company-groups';
import { isNotEmpty, noop } from '@/utils/helpers';

interface EditRestaurantCompanyGroupsFormProps {
  group: LocationGroupWithCompanyLocations;
  companies: ShortRestaurantCompanyWithLocations[];
  isLoadingCompanies: boolean;
  onClose?: () => unknown;
}

export const EditRestaurantCompanyGroupsForm = ({
  group,
  companies,
  isLoadingCompanies,
  onClose = noop,
}: EditRestaurantCompanyGroupsFormProps) => {
  const { t } = useTranslation();
  const memoizedColumns = useMemo(() => {
    return locationsColumns(t);
  }, [t]);

  const [sorting, setSorting] = useState<SortingState>([]);
  const [groupName, setGroupName] = useState<string>(group.name);
  const [companyLocations, setCompanyLocations] = useState<
    ShortRestaurantLocation[]
  >(group.locations as unknown as ShortRestaurantLocation[]);

  const formData = { groupName, companyLocations };
  const serverFormData = {
    groupName: group.name,
    companyLocations: group.locations,
  };

  const uid = useId();
  const restaurant = useAuthUserRestaurant();
  const editRestaurantCompanyGroup = useEditCompanyLocationGroupMutation();

  const [isChanged, changedObj] = useChangedObject(formData, serverFormData, {
    customComparators: {
      companyLocations: (a, b) => {
        if (!Array.isArray(a) || !Array.isArray(b)) return false;
        if (a.length !== b.length) return false;
        return a.every((element) => b.some((el) => el.id === element.id));
      },
    },
  });

  const isValid = useMemo(() => {
    return isNotEmpty(groupName) && companyLocations.length > 0;
  }, [groupName, companyLocations]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isChanged || !isValid) return;
    try {
      await editRestaurantCompanyGroup.mutateAsync({
        restaurant_id: restaurant.id,
        location_group_id: group.id,
        name: changedObj.groupName ? changedObj.groupName : undefined,
        location_ids: changedObj.companyLocations
          ? changedObj.companyLocations.map((loc) => loc.id)
          : undefined,
      });
      onClose();
      toast.success(t('company.groups.edit-success-msg'));
    } catch (error) {
      if (error instanceof ValidationError) {
        return toast.error(error.message);
      }
      toast.error(t('company.groups.edit-error.msg'));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid gap-4 py-4">
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-name'}>{t('common.name')}</Label>
          <Input
            id={uid + '-name'}
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            placeholder={t('common.name-placeholder')}
          />
        </div>
        <div className="flex flex-col gap-2">
          <Label htmlFor={uid + '-locations'}>
            {t('company-locations.title')}
          </Label>
          <div>
            <SelectCompanyLocations
              showSelectAll={false}
              companies={companies}
              isLoading={isLoadingCompanies}
              selectedCompanyLocations={companyLocations}
              onUpdateCompanyLocations={setCompanyLocations}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <Label>{t('company.groups.choosen-locations')}</Label>
          <DataTable
            sorting={sorting}
            setSorting={setSorting}
            isLoading={isLoadingCompanies}
            columns={memoizedColumns}
            data={companyLocations}
            onDeleteTableRow={(tableRowId) => {
              setCompanyLocations((locations) =>
                locations.filter((location) => location.id !== tableRowId),
              );
            }}
          />
        </div>
      </div>
      <DialogFooter className="mt-2">
        <Button
          type="submit"
          isLoading={editRestaurantCompanyGroup.isPending}
          disabled={!isChanged || !isValid}
        >
          {t('common.save-edit')}
        </Button>
      </DialogFooter>
    </form>
  );
};
