import { useMemo, useState } from 'react';
import { format, isEqual } from 'date-fns';
import { ChevronDown } from 'lucide-react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import {
  SHIFT,
  useAuthUserRestaurant,
  useGetAllRestaurantCompaniesQuery,
  useGetIngredientsReportQuery,
} from '@durma-soft/gros-sdk';
import { ShortRestaurantLocation } from '@durma-soft/gros-sdk/dist/types/restaurants';

import { useDatePickerRangeState } from '@/hooks/use-date-picker-range-state';

import { Button } from '@/components/shared/shadcn-ui/button';
import { PrintButton } from '@/components/shared/print-button';
import { Skeleton } from '@/components/shared/shadcn-ui/skeleton';
import { LastUpdatedInfo } from '@/components/shared/last-updated-info';
import { DataTable, DataTableHTML } from '@/components/shared/data-table';
import { DatePickerWithRange } from '@/components/shared/date-picker-with-range';
import { SelectCompanyLocations } from '@/components/shared/select-company-locations';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/shared/shadcn-ui/dropdown-menu';

import print from '@/lib/print/print';

import { columns } from '@/config/restaurant/reports/ingredients';
import { ColumnToShow } from '@/types/ingredients';
import { repeat } from '@/utils/helpers';
import { SortingState } from '@tanstack/react-table';

const MANUAL_TYPE = 'manual_subtraction';

const IngredientsReport = () => {
  const { t } = useTranslation();
  const memoizedColumns = useMemo(() => columns(t), [t]);

  const { from, to, setDateRange } = useDatePickerRangeState();

  const restaurant = useAuthUserRestaurant();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [colsToShowDropdownOpen, setColsToShowDropdownOpen] = useState(false);
  const [companyLocations, setCompanyLocations] = useState<
    ShortRestaurantLocation[]
  >([]);
  const [selectedColumns, setSelectedColumns] = useState<ColumnToShow[]>([
    SHIFT.FIRST,
    SHIFT.SECOND,
    SHIFT.THIRD,
    MANUAL_TYPE,
  ]);

  const {
    data: companiesAndGroups,
    isSuccess: isSuccessCompanies,
    isLoading: isLoadingCompanies,
  } = useGetAllRestaurantCompaniesQuery(restaurant.id);

  const {
    data,
    isLoading: isLoadingIngredients,
    isSuccess: isSuccessIngredients,
    isRefetching,
    dataUpdatedAt,
    refetch,
  } = useGetIngredientsReportQuery({
    restaurant_id: restaurant.id,
    start_date: from,
    end_date: to,
    company_location_ids: companyLocations.length
      ? companyLocations.map(({ id }) => id)
      : [],
  });

  const handleRefetch = async () => {
    await refetch();
  };

  return (
    <>
      <Helmet>
        <title>{t('reports.ingredients.title')}</title>
      </Helmet>
      <div className="flex items-center justify-between">
        <h1 className="text-lg font-semibold md:text-2xl">
          {t('reports.ingredients.title')}
        </h1>
        {(isLoadingCompanies || isLoadingIngredients) && (
          <Skeleton className="h-10 w-52" />
        )}
        {isSuccessCompanies && isSuccessIngredients && (
          <LastUpdatedInfo
            isRefetching={isRefetching}
            dataUpdatedAt={dataUpdatedAt}
            handleRefetch={handleRefetch}
          />
        )}
      </div>
      <div className="flex flex-col gap-3 my-5 lg:flex-row ">
        <div className="flex-1 flex flex-col gap-3 lg:flex-row ">
          <div className="flex flex-col xl:flex-row gap-5">
            <div className="self-start">
              <DatePickerWithRange
                date={{ from: new Date(from), to: new Date(to) }}
                setDate={([from, to]) => setDateRange({ from, to })}
              />
            </div>
            <DropdownMenu
              open={colsToShowDropdownOpen}
              onOpenChange={setColsToShowDropdownOpen}
            >
              <DropdownMenuTrigger asChild>
                <Button
                  variant="outline"
                  className="gap-2 font-normal print:hidden flex justify-between w-[200px]"
                >
                  {t('common.choose-shifts')}
                  <span>
                    <ChevronDown size={16} className="opacity-50" />
                  </span>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-[200px]">
                {['1', '2', '3', 'manual_subtraction'].map((col) => (
                  <DropdownMenuCheckboxItem
                    key={col}
                    checked={selectedColumns.includes(col as ColumnToShow)}
                    onSelect={(event) => {
                      event.preventDefault();
                      setSelectedColumns((prev) =>
                        prev.includes(col as ColumnToShow)
                          ? prev.filter((s) => s !== col)
                          : [...prev, col as ColumnToShow],
                      );
                    }}
                  >
                    {col === MANUAL_TYPE
                      ? t('ingredient.manual')
                      : `${col}. ${t('menu.shift')}`}
                  </DropdownMenuCheckboxItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
            <div>
              <SelectCompanyLocations
                selectAllByDefault
                companies={
                  isSuccessCompanies ? companiesAndGroups.companies : []
                }
                groups={isSuccessCompanies ? companiesAndGroups.groups : []}
                isLoading={isLoadingCompanies}
                selectedCompanyLocations={companyLocations}
                onUpdateCompanyLocations={setCompanyLocations}
              />
            </div>
          </div>
        </div>
        <div>
          <PrintButton
            disabled={isLoadingIngredients || isLoadingCompanies}
            onClick={() =>
              print({
                content: (
                  <div>
                    <div>
                      <div className="flex-between">
                        <h1>{t('reports.ingredients.title')}</h1>
                        <div className="flex-row">
                          <p>{t('common.data-updated-at')}</p>
                          <span>&nbsp;</span>
                          <p>
                            <span> {format(dataUpdatedAt, 'dd.MM.yyyy')}</span>
                            <span> {t('common.in')} </span>
                            <span> {format(dataUpdatedAt, 'HH:mm:ss')}</span>
                          </p>
                        </div>
                      </div>
                      <div className="flex-between">
                        <p>
                          {format(from, 'dd.MM.yyyy')}
                          {!isEqual(from, to) &&
                            ` - ${format(to, 'dd.MM.yyyy')}`}
                        </p>
                      </div>
                    </div>
                    <DataTableHTML
                      data={data}
                      sorting={sorting}
                      setSorting={setSorting}
                      columns={memoizedColumns}
                      columnVisibility={{
                        shift_1: selectedColumns.includes(SHIFT.FIRST),
                        shift_2: selectedColumns.includes(SHIFT.SECOND),
                        shift_3: selectedColumns.includes(SHIFT.THIRD),
                        manual_subtraction:
                          selectedColumns.includes(MANUAL_TYPE),
                      }}
                    />
                  </div>
                ),
              })
            }
          />
        </div>
      </div>
      <div className="flex flex-col gap-3">
        {(isLoadingCompanies || isLoadingIngredients) && (
          <div className="flex flex-col gap-10">
            {repeat(5).map((index) => (
              <div key={index}>
                <Skeleton className="w-full rounded-md h-60" />
              </div>
            ))}
          </div>
        )}

        {companyLocations.length === 0 && (
          <p className="self-center">{t('reports.orders.choose-location')}</p>
        )}

        {companyLocations.length !== 0 && (
          <DataTable
            data={data}
            sorting={sorting}
            setSorting={setSorting}
            columns={memoizedColumns}
            isLoading={isLoadingIngredients}
            columnVisibility={{
              shift_1: selectedColumns.includes(SHIFT.FIRST),
              shift_2: selectedColumns.includes(SHIFT.SECOND),
              shift_3: selectedColumns.includes(SHIFT.THIRD),
              manual_subtraction: selectedColumns.includes(MANUAL_TYPE),
            }}
          />
        )}
      </div>
    </>
  );
};

export default IngredientsReport;
