import { useMemo, useState } from 'react';
import { format, isEqual } from 'date-fns';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { SortingState } from '@tanstack/react-table';
import {
  useAuthUserCompany,
  useGetCompanyFoodReportQuery,
} from '@durma-soft/gros-sdk';

import { useDatePickerRangeState } from '@/hooks/use-date-picker-range-state';

import { PrintButton } from '@/components/shared/print-button';
import { Skeleton } from '@/components/shared/shadcn-ui/skeleton';
import { LastUpdatedInfo } from '@/components/shared/last-updated-info';
import { DataTable, DataTableHTML } from '@/components/shared/data-table';
import { DatePickerWithRange } from '@/components/shared/date-picker-with-range';

import print from '@/lib/print/print';

import { columns } from '@/config/company/reports/company-foods';

const CompanyFoodsReport = () => {
  const { t } = useTranslation();
  const memoizedColumns = useMemo(() => columns(t), [t]);

  const [sorting, setSorting] = useState<SortingState>([]);
  const { from, to, setDateRange } = useDatePickerRangeState();

  const company = useAuthUserCompany();

  const { data, isLoading, isSuccess, dataUpdatedAt, isRefetching, refetch } =
    useGetCompanyFoodReportQuery({
      company_id: company.id,
      start_date: from,
      end_date: to,
    });

  const handleRefetch = async () => {
    await refetch();
  };

  return (
    <>
      <Helmet>
        <title>{t('reports.foods.title')}</title>
      </Helmet>
      <div className="flex items-center justify-between">
        <h1 className="text-lg font-semibold md:text-2xl">
          {t('reports.foods.title')}
        </h1>
        {isLoading && <Skeleton className="h-10 w-52" />}
        {isSuccess && (
          <LastUpdatedInfo
            isRefetching={isRefetching}
            dataUpdatedAt={dataUpdatedAt}
            handleRefetch={handleRefetch}
          />
        )}
      </div>
      <div className="flex flex-col items-start lg:flex-row lg:items-center lg:justify-between mt-5 mb-5 gap-3">
        <div>
          <DatePickerWithRange
            date={{ from: new Date(from), to: new Date(to) }}
            setDate={([from, to]) => setDateRange({ from, to })}
          />
        </div>
        <PrintButton
          disabled={isLoading}
          onClick={() =>
            print({
              content: (
                <div>
                  <div>
                    <div className="flex-between">
                      <h1>{t('reports.foods.title')} </h1>
                      <div className="flex-row">
                        <p>{t('common.data-updated-at')}</p>
                        <span>&nbsp;</span>
                        <p>
                          <span> {format(dataUpdatedAt, 'dd.MM.yyyy')}</span>
                          <span> {t('common.in')} </span>
                          <span> {format(dataUpdatedAt, 'HH:mm:ss')}</span>
                        </p>
                      </div>
                    </div>
                    <div className="flex-between">
                      <p>
                        {format(from, 'dd.MM.yyyy')}
                        {!isEqual(from, to) && ` - ${format(to, 'dd.MM.yyyy')}`}
                      </p>
                    </div>
                  </div>
                  <DataTableHTML
                    data={data}
                    columns={memoizedColumns}
                    sorting={sorting}
                    setSorting={setSorting}
                  />
                </div>
              ),
            })
          }
        />
      </div>
      <div className="flex flex-col gap-3">
        <DataTable
          data={data}
          sorting={sorting}
          setSorting={setSorting}
          isLoading={isLoading}
          columns={memoizedColumns}
        />
      </div>
    </>
  );
};

export default CompanyFoodsReport;
